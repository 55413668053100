import React from 'react';
import styles from './Question.module.scss';

const question = props => {
  let title;

  if (props.title) {
    title = <p className={styles.title}>{props.title}</p>;
  }

  return (
    <dt className={`${styles.wrapper} ${props.className}`}>
      {title}
      <p className={styles.copy}>{props.children}</p>
    </dt>
  );
};

export default question;
