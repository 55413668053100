import React, { useRef, useEffect } from 'react';
import styles from './VideoPlayer.module.scss';
import videojs from 'video.js';
import { ReactComponent as CloseIcon } from './../../assets/icons/times.svg';

const VideoPlayer = ({ src, closeHandler }) => {
  const playerRef = useRef();

  useEffect(() => {
    const player = videojs(
      playerRef.current,
      { muted: false, controls: true, autoplay: true },
      () => {
        player.src(src);
      }
    );

    return () => {
      player.dispose();
    };
  }, [src]);

  const close = () => {
    closeHandler();
  };

  return (
    <div>
      <div
        className={styles.backdrop}
        onClick={e => {
          close();
        }}
      />
      <div className={styles.video}>
        <button
          className={styles.btnClose}
          onClick={e => {
            close();
          }}
        >
          <CloseIcon />
        </button>
        <div data-vjs-player>
          <video ref={playerRef} className="video-js vjs-16-9" />
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
