import React from 'react';
import styles from './screens.module.scss';

import Title from './../components/Title/Title';
import Separator from './../components/Separator/Separator';
import Section from './../components/Section/Section';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';

const home = () => {
  return (
    <>
      <Helmet>
        <title>Sitemap | Palliatieve zorg</title>
      </Helmet>
      <div className="container-fluid">
        <Section>
          <div className={`row ${styles.background}`}>
            <div className="col-md-8">
              <Title title="Sitemap" />
            </div>
          </div>
        </Section>

        <Separator />

        <div className="row">
          <div className="col-md-7 offset-md-2">
            <Section>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/gesprek">Gesprek</Link>
                </li>
                <li>
                  <Link to="/zorg-en-hulp">Zorg en hulp</Link>
                </li>
                <li>
                  <Link to="/keuzes">Keuzes</Link>
                </li>
                <li>
                  <Link to="/ervaringsverhalen">Verhalen</Link>
                </li>
                <li>
                  <Link to="/over">Over palliatieve zorg</Link>
                  <ul>
                    <li>
                      <Link to="/over/vragen">Vragen</Link>
                    </li>
                    <li>
                      <Link to="/over/kosten">Kosten</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/cookies">Cookies</Link>
                </li>
                <li>
                  <Link to="/privacy">Privacy</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
                <li>
                  <Link to="/sitemap">Sitemap</Link>
                </li>
              </ul>
            </Section>
          </div>
        </div>
      </div>
    </>
  );
};

export default home;
