import React from 'react';
import styles from './screens.module.scss';

import Title from './../components/Title/Title';
import Separator from './../components/Separator/Separator';
import IntroCopy from './../components/IntroCopy/IntroCopy';
import Section from './../components/Section/Section';
import Heading from './../components/Heading/Heading';

const home = () => {
  return (
    <>
      <div className="container-fluid">
        <Section>
          <div className={`row ${styles.background}`}>
            <div className="col-md-8">
              <Title title="Privacy" />
              <IntroCopy>
                <p>
                  Overpalliatievezorg.nl is een platform waar iedereen die te
                  maken heeft met palliatieve zorg relevante informatie kan
                  vinden. De site bevat content van PZNL en haar leden, maar ook
                  van derde partijen.
                </p>
                <p>
                  Om onze diensten aan te bieden verwerken wij persoonsgegevens.
                  Wij vinden het van essentieel belang dat de persoonsgegevens
                  van onze website bezoekers en overige relaties met de grootst
                  mogelijke zorgvuldigheid worden behandeld en beveiligd. Wij
                  willen hierover transparant zijn en informeren je daarom in
                  deze Privacy verklaring over hoe wij met jouw persoonsgegevens
                  omgaan.
                </p>
              </IntroCopy>
            </div>
          </div>
        </Section>

        <Separator />

        <div className="row">
          <div className="col-md-7 offset-md-2">
            <Section>
              <Heading rank={2}>Contactgegevens</Heading>
              <p>
                Het platform overpalliatievezorg.nl is ontwikkeld door het
                Ministerie van VWS en wordt onderhouden door de Coöperatie
                Palliatieve Zorg Nederland (PZNL). PZNL is de
                verwerkingsverantwoordelijke in de zin van de Algemene
                Verordening Gegevensbescherming (‘AVG’).
              </p>
              <p>
                Voor vragen zijn wij als volgt bereikbaar: <br />
                Coöperatie Palliatieve Zorg Nederland U.A. <br />
                Janssoenborch, 8e etage, Godebaldkwartier 419 3511 DT Utrecht
                <br />
                KvK 71512608 <br />
                E: info@pznl.nl
              </p>
            </Section>
            <Section>
              <Heading rank={2}>
                Welke persoonsgegevens verwerken wij van je?
              </Heading>
              <strong>
                Persoonsgegevens die wij van je verwerken als je contact met ons
                opneemt
              </strong>
              <p>
                Als je persoonsgegevens verstrekt via de telefoon of e-mail,
                registreren wij deze. Bijvoorbeeld je naam, e-mailadres,
                woonplaats en overige gegevens die je zelf verstrekt zoals de
                inhoud van je vraag, verzoek of klacht.
              </p>
              <strong>
                Persoonsgegevens die wij van je ontvangen of genereren door je
                gebruik van onze nieuwsbrieven, onze website of uitnodigingen
                per e-mail
              </strong>
              <p>
                Wij kunnen, wanneer je onze digitale nieuwsbrieven of digitale
                persberichten leest, aan je mailadres zien of je deze berichten
                opent en waar je op klikt. Bij je bezoek aan onze Website
                verzamelen wij geen IP-adressen en we plaatsen geen tracking
                cookies (zie hierover de cookie paragraaf verderop in deze
                Privacyverklaring). Jouw online gedrag kunnen wij geanonimiseerd
                analyseren maar het herleiden naar een IP-adres hebben wij
                onmogelijk gemaakt op al onze analyseplatforms.
              </p>
              <strong>
                Persoonsgegevens die je geeft vanwege een zakelijke relatie met
                ons
              </strong>
              <p>
                Als je een leverancier van ons bent kunnen wij je
                persoonsgegevens vastleggen zoals voor- en achternaam, werk en
                privé e-mailadres, werk en privé telefoonnummer, naam van de
                organisatie waar je werkt, gegevens over de geleverde goederen
                en diensten en de afhandeling daarvan.
              </p>
            </Section>
            <Section>
              <Heading rank={2}>Persoonsgegevens uit openbare bronnen</Heading>
              <p>
                Wanneer je contact met ons opneemt of wanneer wij een
                overeenkomst met je aangaan kunnen wij persoonsgegevens van je
                verwerken die wij krijgen uit (openbare) bronnen, bijvoorbeeld
                zakelijke websites zoals LinkedIn, het Handelsregister van de
                Kamer van Koophandel en het Kadaster.
              </p>
              <strong>
                Voor welke doeleinden verwerken wij jouw persoonsgegevens?
              </strong>
              <p>
                Wij gebruiken je persoonsgegevens voor verschillende doeleinden.
                Wij zetten ze voor je op een rij:
              </p>
              <ul>
                <li>Informeren over (palliatieve) zorg</li>
                <li>
                  Voldoen aan wet- en regelgeving en andere op ons rustende
                  verplichtingen
                </li>
                <li>Versturen van nieuwsbrieven en andere informatie</li>
                <li>Afhandelen van vragen, verzoeken en wensen</li>
                <li>Verbeteren en optimaliseren van de Website</li>
                <li>
                  Verbeteren van onze informatievoorziening en het toespitsen
                  van de informatie op de ontvangers
                </li>
              </ul>
              <p>
                Het platform verwerkt persoonsgegevens via onder andere de
                Website. Buiten bovengenoemde verwerkingsdoeleinden, verwerkt
                Overpalliatievezorg.nl jouw persoonsgegevens niet zonder je
                voorafgaande toestemming.
              </p>
              <strong>Wat is de grondslag voor de gegevensverwerking?</strong>
              <p>
                Wij verwerken je persoonsgegevens uitsluitend wanneer daarvoor
                een wettelijke grondslag aanwezig is. Welke grondslag van
                toepassing is, hangt af van de specifieke gegevensverwerking die
                wij voor je uitvoeren.
              </p>
              <ul>
                <li>
                  Uitvoering van een overeenkomst. Wij kunnen persoonsgegevens
                  verwerken omdat wij een overeenkomst met je hebben of zullen
                  aangaan.
                </li>
                <li>
                  Gerechtvaardigd belang. Wij kunnen een gerechtvaardigd belang
                  hebben bij de gegevensverwerkingen. Dit is het geval wanneer
                  wij onze systemen beveiligen, of onze dienstverlening wensen
                  te monitoren en te verbeteren en bijvoorbeeld als wij
                  informatie aan je verstrekken (op jouw verzoek).
                  Vanzelfsprekend hechten wij waarde aan jouw privacybelang en
                  maken wij altijd vooraf een belangenafweging.
                </li>
                <li>
                  Wettelijke verplichting. Het kan zijn dat er een wettelijke
                  verplichting op ons rust waarvoor wij persoonsgegevens van je
                  moeten verwerken.
                </li>
                <li>
                  Toestemming. In sommige gevallen vragen wij je toestemming
                  voor het verwerken van je persoonsgegevens. Wanneer wij je om
                  toestemming vragen dan informeren wij je duidelijk waarvoor
                  wij dit vragen en je kunt je toestemming te allen tijde
                  intrekken. Wij verwerken je persoonsgegevens dan niet meer.
                </li>
              </ul>
            </Section>
            <Section>
              <Heading rank={2}>
                Met wie kunnen wij je persoonsgegevens delen?
              </Heading>
              <p>
                Wij kunnen je persoonsgegevens delen met partijen die namens ons
                bepaalde diensten uitvoeren en als verwerker namens ons
                persoonsgegevens verwerken. Wij maken bijvoorbeeld gebruik van
                een externe partij om onze Website te hosten. Wij maken
                afspraken met deze verwerkers om vertrouwelijke en zorgvuldige
                omgang met persoonsgegevens te waarborgen. Deze afspraken worden
                contractueel vastgelegd in zogeheten 'verwerkersovereenkomsten'.
              </p>
              <p>
                Wij kunnen je persoonsgegevens ook delen met derde partijen die
                kwalificeren als verwerkingsverantwoordelijke. Derden zijn zelf
                verantwoordelijk voor de wijze waarop zij jouw persoonsgegevens
                verwerken en hebben een eigen verplichting om je hierover te
                informeren. Wij kunnen jouw persoonsgegevens delen met
                handhavende autoriteiten of fraude bestrijdende organisaties,
                wanneer dat noodzakelijk is om te voldoen aan een wettelijke
                verplichting (vordering).
              </p>
            </Section>

            <Section>
              <Heading rank={2}>
                Worden je persoonsgegevens verwerkt buiten de EER?
              </Heading>
              <p>
                Je persoonsgegevens worden in beginsel uitsluitend binnen de
                Europese Economische Ruimte (‘EER’) verwerkt.
              </p>
              <p>
                Overpalliatievezorg.nl kan gebruik maken van e-mail- en
                hostingdienstverleners die buiten de EER zijn gevestigd. In
                voorkomende gevallen zullen wij maatregelen nemen om de
                doorgifte van persoonsgegevens te legitimeren, bijvoorbeeld door
                te controleren of een partij is aangesloten bij het Privacy
                Shield (VS) of door een doorgifte overeenkomst te sluiten
                gebaseerd op de Standard Contractual Clauses.
              </p>
            </Section>
            <Section>
              <Heading rank={2}>Zijn je persoonsgegevens veilig?</Heading>
              <p>
                Overpalliatievezorg.nl gaat vertrouwelijk om met
                persoonsgegevens. Ook nemen wij passende technische en
                organisatorische maatregelen, zodat persoonsgegevens worden
                beschermd tegen verlies of onrechtmatig gebruik. Zo beveiligen
                wij onze systemen en applicaties volgens de geldende standaarden
                voor informatiebeveiliging. Wij zorgen ervoor dat alleen
                geautoriseerde medewerkers en vrijwilligers toegang hebben tot
                je persoonsgegevens, die gebonden zijn aan wettelijke en
                contractuele geheimhoudingsverplichtingen.
              </p>
            </Section>
            <Section>
              <Heading rank={2}>
                Hoe lang bewaren wij je persoonsgegevens?
              </Heading>
              <p>
                Overpalliatievezorg.nl bewaart jouw persoonsgegevens in
                overeenstemming met de AVG. De gegevens worden niet langer
                bewaard dan strikt noodzakelijk is om de doelen te bereiken
                waarvoor de gegevens verzameld zijn. De bewaartermijn die wij
                hanteren is afhankelijk van het doel waarvoor wij jouw
                persoonsgegevens vewerken en of er specifieke wettelijke
                bewaarplichten op ons rusten.
              </p>
            </Section>
            <Section>
              <Heading rank={2}>Jouw rechten als betrokkenen zijn:</Heading>
              <ul>
                <li>recht van informatie;</li>
                <li>recht op inzage;</li>
                <li>
                  recht op verbetering, aanvulling, verwijdering of afscherming;
                </li>
                <li>recht op dataportabiliteit;</li>
                <li>recht op vergetelheid;</li>
                <li>recht op beperking van de verwerking.</li>
              </ul>
              <p>
                Voor het uitoefenen van (een van deze) rechten, kun je een
                verzoek richten aan Overpalliatievezorg.nl. Houd er rekening mee
                dat het verstrekken van een kopie van een legitimatiebewijs
                nodig kan zijn om je identiteit te controleren.
              </p>
              <p>
                Als je klachten hebt over hoe wij met jouw persoonsgegevens
                omgaan, dan kunt je contact met ons opnemen via de
                contactgegevens in deze Privacy Verklaring. Wij helpen je graag
                met het vinden van een oplossing. Indien dat toch niet zou
                lukken, dan kunt je je wenden tot de Autoriteit
                Persoonsgegevens. Zie hiervoor www.autoriteitpersoonsgegevens.nl
              </p>
            </Section>
          </div>
        </div>
      </div>
    </>
  );
};

export default home;
