import React from 'react';
import styles from './Quote.module.scss';

import Button from './../Button/Button';
import Cite from './Cite';

const quote = props => {
  let button;
  let title;
  const classes = [styles.wrapper];

  if (props.icon) {
    classes.push(styles.wrapperWithIcon);
  }

  if (props.button) {
    button = (
      <Button className={styles.button} color="quote" href={props.button.href}>
        {props.button.copy}
      </Button>
    );

    classes.push(styles.wrapperWithButton);
  }

  if (props.carousel) {
    classes.push(styles.wrapperCarousel);
  }

  if (props.title) {
    title = <strong className={styles.title}>{props.title}</strong>;
  }

  classes.push(props.className);

  return (
    <div className={classes.join(' ')} style={props.style}>
      {title}
      <blockquote className={styles.quote}>
        <p>{props.quote}</p>
      </blockquote>
      <Cite {...props} />
      {button}
    </div>
  );
};

export default quote;
