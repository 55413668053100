import React from 'react';
import styles from './Answer.module.scss';

import Link from './../Link/Link';

const answer = props => {
  let title;
  let link;
  const copyClasses = [styles.copy];

  if (props.title) {
    title = <p className={styles.title}>{props.title}</p>;
  }

  if (props.link) {
    link = (
      <Link
        className={styles.link}
        href={props.link.href}
        style={{ margin: 0 }}
      >
        {props.link.copy}
      </Link>
    );
  }

  if (props.quotes) {
    copyClasses.push(styles.quotes);
  }

  return (
    <dd className={`${styles.wrapper} ${props.className}`}>
      {title}
      <p className={copyClasses.join(' ')}>{props.children}</p>
      {link}
    </dd>
  );
};

export default answer;
