import React, { useState, useRef, useEffect } from 'react';
import styles from './VideoThumb.module.scss';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import Cite from '../Quote/Cite';
import useWindowSize from './../../hooks/useWindowSize';
import videojs from 'video.js';

const VideoThumb = ({ imageSrc, videoSrc, alt, quote, cite, age }) => {
  const playerRef = useRef();
  const [showPlayer, setShowPlayer] = useState(false);
  const { width } = useWindowSize();
  const isMobile = width < 768;
  let thumbnail, modal, player;

  useEffect(() => {
    if (isMobile) {
      const player = videojs(
        playerRef.current,
        { muted: false, controls: true, autoplay: false, poster: imageSrc },
        () => {
          player.src(videoSrc);
        }
      );

      return () => {
        player.dispose();
      };
    }
  }, [imageSrc, isMobile, videoSrc]);

  const openVideoHandler = () => {
    setShowPlayer(true);
  };

  const closeHandler = () => {
    setShowPlayer(false);
  };

  if (isMobile) {
    player = (
      <div data-vjs-player>
        <video
          ref={playerRef}
          className={`video-js vjs-16-9 ${styles.player}`}
        />
      </div>
    );
  }

  if (!isMobile) {
    thumbnail = (
      <button
        onClick={e => {
          openVideoHandler();
        }}
        className={styles.button}
      >
        <img src={imageSrc} alt={alt} />
      </button>
    );
  }

  if (!isMobile && showPlayer) {
    modal = (
      <VideoPlayer src={videoSrc} closeHandler={closeHandler} autoplay={true} />
    );
  }

  return (
    <>
      <div className={styles.wrapper}>
        {thumbnail}
        {player}
        <blockquote className={styles.quote}>
          <p>{quote}</p>
        </blockquote>
        <Cite cite={cite} age={age} />
      </div>
      {modal}
    </>
  );
};

export default VideoThumb;
