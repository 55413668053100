import React from 'react';
import styles from './IntroCopy.module.scss';

const introCopy = props => {
  const classes = [styles.copy, props.className];

  return <p className={classes.join(' ')}>{props.children}</p>;
};

export default introCopy;
