import React from 'react';
import styles from './screens.module.scss';

import data from './../data/stories.json';

import { Helmet } from 'react-helmet';
import Title from './../components/Title/Title';
import Separator from './../components/Separator/Separator';
import IntroCopy from './../components/IntroCopy/IntroCopy';
import Section from './../components/Section/Section';
import Heading from './../components/Heading/Heading';
import getStories from '../utils/stories';

const home = () => {
  const items = getStories(data);

  return (
    <>
      <Helmet>
        <title>Verhalen van anderen | Palliatieve zorg</title>
        <meta
          name="description"
          content="Hoe iemand omgaat met de tijd die hij of zij nog heeft, is erg persoonlijk. Welke zorg en hulp is er nodig in deze moeilijke periode? Hier leest u de verhalen van anderen."
        />
        <meta name="keywords" content="palliatieve, zorg, verhalen" />
      </Helmet>
      <div className="container-fluid">
        <Section>
          <div className={`row ${styles.background}`}>
            <div className="col-md-8">
              <Title title="Zij vertellen hun verhaal" />
              <IntroCopy>
                Hoe iemand omgaat met de tijd die hij of zij nog heeft, is erg
                persoonlijk. Want waar de een graag nog iets bijzonders wil
                meemaken, wil een ander alles juist zo normaal mogelijk laten
                verlopen. En welke zorg en hulp is er nodig in deze moeilijke
                periode? Hier leest u de verhalen van anderen.
              </IntroCopy>
            </div>
          </div>
        </Section>

        <Separator />

        <div className="row">
          <div className="col-md-3 offset-md-1">
            <Heading rank={2}>Bekijk de verhalen van anderen</Heading>
          </div>
        </div>
        <div className="row">
          <div className="col-md-9 offset-md-1">{items}</div>
        </div>
      </div>
    </>
  );
};

export default home;
