import React, { useEffect } from 'react';
import styles from './screens.module.scss';

import Hero from './../components/Hero/Hero';
import Title from './../components/Title/Title';
import Separator from './../components/Separator/Separator';
import IntroCopy from './../components/IntroCopy/IntroCopy';
import Quote from './../components/Quote/Quote';
import Section from './../components/Section/Section';
import desktopVisual from './../assets/images/palliatieve-zorg-jonge-vrouw-in-kas-large.jpg';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import Panel from './../components/Panel/Panel';
import ScrollLink from './../components/ScrollLink/ScrollLink';
import Link from './../components/Link/Link';
import Heading from './../components/Heading/Heading';
import Questions from './../components/Questions/Questions';
import Question from './../components/Question/Question';
import Answer from './../components/Answer/Answer';
import Button from './../components/Button/Button';
import Carousel from '../components/Carousel/Carousel';
import { Helmet } from 'react-helmet';
import * as Stickyfill from 'stickyfilljs';

const Help = () => {
  useEffect(() => {
    const elements = document.querySelectorAll('.sticky');
    Stickyfill.add(elements);
  }, []);

  return (
    <>
      <Helmet>
        <title>Er is zorg en hulp voor u | Palliatieve zorg</title>
        <meta
          name="description"
          content="Als u ongeneeslijk ziek bent, of in de terminale fase, dan kunt u rekenen op zorg en hulp - van pijnmedicatie tot psychische hulp. U vindt hier een overzicht."
        />
        <meta
          name="keywords"
          content="palliatieve zorg, thuiszorg, terminaal"
        />
      </Helmet>
      <div className="container-fluid">
        <Hero>
          <Title
            title="Er is zorg en hulp voor u"
            fixedBackgroundHeight
            image={{
              src: null,
              desktopSrc: desktopVisual,
              position: 'top',
              alt: 'Jonge vrouw staat in kas'
            }}
            className={styles.helpTitle}
          />
          <IntroCopy>
            Er komt ongetwijfeld veel op u en uw dierbaren af. U staat er niet
            alleen voor: er is hulp en zorg op allerlei gebieden. Soms is de
            zorg al voor u geregeld, bijvoorbeeld als u in het verpleeghuis
            woont of lang in het ziekenhuis ligt. Maar zeker als u thuis blijft,
            is het belangrijk dat u en uw dierbaren weten wat er mogelijk is.
          </IntroCopy>

          <Separator />

          <div className="row">
            <div className="col-md-6">
              <Heading rank={2}>Zorg en hulp</Heading>
              <p>
                Uw arts(en) en verpleegkundigen zorgen ervoor dat u zo weinig
                mogelijk last heeft van klachten zoals pijn. Maar er is meer
                zorg voor u in deze periode van uw leven. Geestelijke verzorging
                bijvoorbeeld, en praktische hulp in huis.
              </p>

              <ScrollLink to="/zorg-en-hulp#uw-zorgaanbod">
                Bekijk zorg en hulp
              </ScrollLink>
            </div>

            <div className="col-12 d-block d-md-none">
              <Separator />
            </div>

            <div className="col-md-6">
              <Heading rank={2}>Levenswensen</Heading>
              <p>
                Is er iets dat u nog graag zou willen doen? Daar kunnen
                verschillende organisaties u bij helpen, zoals Ambulancewens,
                Vaarwens en Dreams4you. We laten een paar voorbeelden zien van
                wensen die zijn uitgekomen.
              </p>
              <ScrollLink to="/zorg-en-hulp#ideeen-voor-levenswensen">
                Hulp bij uw wensen
              </ScrollLink>
            </div>
          </div>
        </Hero>
      </div>

      <Separator />

      <div className="container-fluid" id="uw-zorgaanbod">
        <Section className="row">
          <div className="col-12">
            <Heading rank={2} className="large">
              Zorg en hulp
            </Heading>
          </div>
          <div className="col-md-3 offset-md-1">
            <Heading rank={2}>Voor kwaliteit van leven</Heading>
            <p>
              Wat heeft u nodig in de laatste periode van uw leven? U kunt zelf
              kiezen welke zorg en hulp bij u past. Zodat u, in de tijd die u
              nog heeft, kunt leven op uw manier.
            </p>
          </div>

          <div className="col-md-7 offset-md-1">
            <Carousel>
              <Quote
                icon
                title="Thuiszorg van de wijkverpleegkundige"
                quote="In het begin had ik wel moeite met de gedachte dat er doorlopend vreemden in mijn huis zouden rondlopen. Je levert toch een stukje privacy in. Gelukkig valt het mee. Ik ben heel erg blij dat ze er zijn."
                cite="Wiljohn"
                age="43 jaar"
                carousel
              />
              <Quote
                icon
                title="Praten met lotgenoten"
                quote="Ik praat veel met een lotgenoot. We hebben elkaar toevallig ontmoet bij een wandelclubje en zijn naar elkaar toegegroeid door onze ziekte. Het is fijn om met elkaar van gedachten te wisselen; we voelen elkaar ontzettend goed aan en herkennen veel situaties."
                cite="Monique"
                age="66 jaar"
                carousel
              />
              <Quote
                icon
                title="Hospices"
                quote="Veel zieken voelen zich thuis snel teveel. Ze voelen zich bezwaard door de extra last die ze op hun familie en vrienden leggen. Bij ons valt die druk weg."
                cite="Ellie Coenen (vrijwilliger)"
                age="51 jaar"
                carousel
              />
              <Quote
                icon
                title="Voorbereiding op een doktersafspraak"
                quote="Als huisarts probeer ik ervoor zorg te dragen dat een terminale gast zo comfortabel mogelijk blijft. Symptomen als pijn, benauwdheid, misselijkheid en verwardheid moeten draaglijk blijven."
                cite="Han Wassenberg (huisarts)"
                age="69 jaar"
                carousel
              />
            </Carousel>
          </div>
        </Section>
      </div>

      <Separator />

      <div className="container-fluid">
        <Section className="row">
          <div className="col-md-7 offset-md-1">
            <Accordion
              allowMultipleExpanded={true}
              allowZeroExpanded={true}
              preExpanded={[0]}
            >
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span className="suptitle">Verblijf</span>
                    <span className="title">Kiezen waar u wilt zijn</span>
                    <p className="intro">
                      Het is moeilijk te voorspellen hoe deze laatste fase van
                      uw leven verloopt. Maar u kunt wel alvast nadenken over
                      wat u zelf graag zou willen. U kunt namelijk kiezen of u
                      bijvoorbeeld thuis verblijft of in een hospice, of op een
                      andere plek waar u zich veilig voelt.
                    </p>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Panel title="Thuis blijven">
                    <p>
                      Wilt u het liefst in uw vertrouwde omgeving blijven, dan
                      is het belangrijk dat er genoeg zorg is. De huisarts en de
                      wijkverpleegkundige zorgen ervoor dat u zo goed mogelijk
                      verzorgd wordt. Daarnaast zijn er andere organisaties die
                      kunnen helpen, zoals vrijwilligersorganisaties. Uw
                      gemeente kan vaak voor praktische hulp zorgen.
                    </p>

                    <Link
                      indent
                      href="https://www.zorgkaartnederland.nl/thuiszorg"
                    >
                      Thuiszorg vinden op zorgkaartnederland.nl
                    </Link>
                    <Link indent href="https://www.vptz.nl/organisaties/">
                      Vrijwilligersorganisaties vinden
                    </Link>
                    <Link
                      indent
                      href="https://www.rijksoverheid.nl/onderwerpen/zorg-en-ondersteuning-thuis/vraag-en-antwoord/ondersteuning-gemeente-wmo-2015"
                    >
                      Dit kan de gemeente voor u doen
                    </Link>
                  </Panel>

                  <Panel title="Hospice">
                    <p>
                      Een hospice is een plek waar u uw laatste levensfase kunt
                      doorbrengen. Dit zijn vaak kleinschalige plekken met een
                      huiselijke sfeer. U krijgt hier een eigen kamer en vaak
                      alle vrijheid om die zo in te richten als u zelf prettig
                      vindt. Uw huisarts blijft meestal verantwoordelijk voor uw
                      medische zorg. Als de huisarts te ver weg woont, dan kan
                      het zijn dat u een huisarts uit de regio krijgt.
                    </p>
                    <Link
                      indent
                      href="https://www.regelhulp.nl/ik-heb-hulp-nodig/hospice"
                    >
                      Meer over hospices op regelhulp.nl
                    </Link>
                    <Link
                      indent
                      href="https://www.zorgkaartnederland.nl/hospice"
                    >
                      Hospice vinden op zorgkaartnederland.nl
                    </Link>
                  </Panel>

                  <Panel title="Andere mogelijkheden">
                    <p>
                      Naast hospices zijn er ook andere mogelijkheden als u niet
                      thuis wilt overlijden. Kortdurende zorgopname
                      bijvoorbeeld, woonzorgcentra en het ziekenhuis.
                    </p>
                    <Link
                      indent
                      href="https://www.ongeneeslijk.nl/Landelijke-Content/lcid/16/Zorg/Zorg-elders"
                    >
                      Andere plaatsen waar u kunt verblijven
                    </Link>
                  </Panel>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span className="suptitle">Lichamelijke zorg</span>
                    <span className="title">
                      Pijn verlichten en andere medische hulp
                    </span>
                    <p className="intro">
                      Er zijn verschillende manieren om ervoor te zorgen dat u
                      zich zo goed mogelijk voelt in deze fase van uw leven. Uw
                      huisarts en uw wijkverpleegkundige kunnen u er alles over
                      vertellen.
                    </p>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Panel title="Voorbereiding op een doktersafspraak">
                    <p>
                      Een afspraak met uw huisarts of een andere arts kan best
                      overweldigend zijn. Daarom is het goed om aandacht te
                      besteden aan de voorbereiding. Maak bijvoorbeeld een lijst
                      van de vragen die u heeft.
                    </p>
                    <Link
                      indent
                      href="https://www.thuisarts.nl/bezoek-aan-huisarts/ik-ga-naar-huisarts-wat-kan-ik-van-tevoren-doen-en-verwachten"
                    >
                      Meer tips voor gesprekken met de dokter
                    </Link>
                    <Link indent href="https://www.ongeneeslijk.nl/">
                      Klachten en oplossingen op ongeneeslijk.nl
                    </Link>
                  </Panel>

                  <Panel title="Thuiszorg van de wijkverpleegkundige">
                    <p>
                      Wijkverpleegkundigen kunnen in iedere wijk zorg leveren.
                      Als u tijdens uw laatste levensfase thuis bent of in een
                      hospice, dan komt deze verpleegkundige met u bespreken
                      welke verpleging en verzorging u nodig heeft. Dat kan
                      lichamelijke verzorging zijn, maar ook psychische hulp.
                    </p>
                    <Link
                      indent
                      href="https://www.zorgkaartnederland.nl/thuiszorg"
                    >
                      Vind thuiszorg op zorgkaartnederland.nl
                    </Link>
                  </Panel>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span className="suptitle">Geestelijke zorg</span>
                    <span className="title">
                      Psychische zorgen of levensvragen bespreken
                    </span>
                    <p className="intro">
                      Het einde van uw leven kan veel losmaken. Angst
                      bijvoorbeeld, of gevoelens van onrechtvaardigheid. Overleg
                      gerust met uw huisarts of u hierbij begeleiding kunt
                      krijgen.
                    </p>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Panel title="Begin bij de huisarts">
                    <p>
                      De huisarts is de eerste persoon bij wie u terechtkunt als
                      u het psychisch moeilijk heeft. Soms is het genoeg om met
                      de huisarts uw zorgen te delen. De huisarts kan u ook
                      doorverwijzen naar een therapeut of geestelijk verzorger.
                    </p>
                    <Link
                      indent
                      href="https://www.thuisarts.nl/hulp-bij-psychische-klachten-bij-volwassenen"
                    >
                      Hulp bij psychische klachten op thuisarts.nl
                    </Link>
                    <Link
                      indent
                      href="https://www.rijksoverheid.nl/onderwerpen/geestelijke-gezondheidszorg"
                    >
                      Meer over geestelijke gezondheidszorg
                    </Link>
                  </Panel>

                  <Panel title="Vragen over het leven bespreken">
                    <p>
                      Het is niet gek als u aan het einde van uw leven zoekt
                      naar antwoorden op vragen over leven en dood. Geestelijk
                      verzorgers helpen u hier graag bij. Er zijn geestelijk
                      verzorgers voor alle religies en ook voor atheïsten en
                      agnosten. In veel instellingen werken geestelijk
                      verzorgers. Er zijn ook steeds meer geestelijk verzorgers
                      die aan huis komen.
                    </p>
                    <Link indent href="https://geestelijkeverzorging.nl">
                      Geestelijk verzorger vinden op geestelijkeverzorging.nl
                    </Link>
                  </Panel>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span className="suptitle">Sociale contacten en hulp</span>
                    <span className="title">
                      Mantelzorgers, vrijwilligers, lotgenoten
                    </span>
                    <p className="intro">
                      Ook mantelzorgers en vrijwilligers kunnen veel voor u en
                      uw naasten betekenen. Daarnaast zijn er manieren om in
                      contact te komen met lotgenoten.
                    </p>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Panel title="Mantelzorgers hebben ook hulp nodig">
                    <p>
                      Het zorgen voor iemand die doodgaat, is in allerlei
                      opzichten zwaar. Als u mantelzorger bent, probeer dan ook
                      nog tijd voor uzelf te nemen. U kunt de zorgtaken even
                      over laten nemen door een professionele kracht of een
                      vrijwilliger. Dat heet Respijtzorg.
                    </p>
                    <Link
                      indent
                      href="https://www.rijksoverheid.nl/onderwerpen/mantelzorg/vraag-en-antwoord/hoe-kan-ik-als-mantelzorger-hulp-bij-de-zorg-krijgen"
                    >
                      Meer over Respijtzorg op rijksoverheid.nl
                    </Link>
                    <Link
                      indent
                      href="https://mantelzorg.nl/pagina/voor-mantelzorgers/thema-s/delen-van-de-zorg/vervangende-zorg-voor-mantelzorgers-respijtzorg"
                    >
                      Vind hulp op mantelzorg.nl
                    </Link>
                  </Panel>

                  <Panel title="Hulp van vrijwilligers">
                    <p>
                      Er zijn in Nederland ruim 200 organisaties met opgeleide
                      vrijwilligers die mensen die terminaal ziek zijn
                      begeleiden. Ze werken in hospices en ook bij mensen thuis
                      en steeds vaker ook in zorginstellingen. Deze
                      vrijwilligers zijn er voor u en ook voor uw naasten. De
                      vrijwilligers werken samen met de professionele
                      verpleegkundigen, de huisarts en uw familieleden.
                    </p>
                    <Link indent href="https://www.vptz.nl/organisaties/">
                      Meer over vrijwilligers op vptz.nl
                    </Link>
                  </Panel>

                  <Panel title="Praten met lotgenoten">
                    <p>
                      Er zijn dingen die alleen een lotgenoot écht begrijpt.
                      Daarom zijn er verschillende manieren om in contact te
                      komen met lotgenoten. Patiëntenverenigingen doen dit
                      bijvoorbeeld. En bijeenkomsten zoals café Doodgewoon. Ook
                      zijn er vele verhalen online te vinden.
                    </p>
                    <Link
                      indent
                      href="https://www.zorgkaartnederland.nl/patientenorganisatie"
                    >
                      Patiëntenverenigingen vinden
                    </Link>
                    <Link
                      indent
                      href="https://www.stichtingfibula.nl/Netwerken-Palliatieve-Zorg/Kaart-Netwerken"
                    >
                      Naar Netwerkpalliatievezorg.nl
                    </Link>
                    <Link indent href="https://www.levenseindeverhalen.nl/">
                      Naar Levenseindeverhalen.nl
                    </Link>
                    <Link
                      indent
                      href="https://mantelzorg.nl/pagina/voor-mantelzorgers/thema-s/steun-en-advies/lotgenotencontact"
                    >
                      Lotgenoten van mantelzorgers
                    </Link>
                  </Panel>

                  <Panel title="Ondersteuning in huis">
                    <p>
                      Het kan zijn dat u in de laatste fase van uw leven
                      ondersteuning nodig heeft. Vervoer bijvoorbeeld, hulp met
                      het huishouden, de boodschappen of aanpassingen aan uw
                      huis. Daar kan de gemeente vaak bij helpen.
                    </p>
                    <Link
                      indent
                      href="https://www.rijksoverheid.nl/onderwerpen/zorg-en-ondersteuning-thuis/vraag-en-antwoord/ondersteuning-gemeente-wmo-2015"
                    >
                      Bekijk een overzicht op rijksoverheid.nl
                    </Link>
                  </Panel>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>

          <div
            className={`order-first order-md-last col-md-3 offset-md-1 sticky ${
              styles.sticky
            }`}
          >
            <Section>
              <Heading rank={2}>
                Uw centrale zorgverlener vindt de zorg die bij u past
              </Heading>
              <p>
                Als u ernstig ziek bent, zijn er vaak meerdere artsen en
                verpleegkundigen die u helpen. Eén van die mensen is uw
                'centrale zorgverlener'. Deze persoon regelt dat alle zorg goed
                op elkaar aansluit. Vaak doet de huisarts dit, maar niet altijd.
                Dat hangt ook af van wat u zelf prettig vindt. Op deze pagina
                ziet u welke zorg en hulp uw centrale zorgverlener voor u kan
                inschakelen.
              </p>
            </Section>
          </div>
        </Section>

        <Separator />

        <div className="row" id="ideeen-voor-levenswensen">
          <Section className="col-md-4" intro>
            <Heading rank={2} className="large">
              Hulp bij uw levenswensen
            </Heading>
            <p>
              Die ene, speciale plek nog eens zien? Of die ene bijzondere
              ervaring toch eens beleven? U heeft best kans dat het mogelijk is.
              Met Ambulancewens, Vaarwens of Dreams4You bijvoorbeeld.
            </p>
          </Section>
        </div>
      </div>

      <Questions className="row">
        <div className="col">
          <div className="container-fluid">
            <div className="row">
              <Question
                title="Inspiratie voor levenswens"
                className="col-9 col-md-6"
              >
                Waar zou u nog één keer naartoe willen?
              </Question>
              <Answer
                className="col-9 offset-3 col-md-6 offset-md-5"
                link={{
                  href: 'https://www.ambulancewens.nl',
                  copy: 'Naar Ambulancewens.nl'
                }}
              >
                “Mijn vrouw had maar één wens: ze wilde dolgraag nog een keer
                naar Schiermonnikoog." Die wens liet Ambulancewens uitkomen.
                Ambulancewens helpt mensen die ongeneeslijk ziek zijn en bijna
                niet meer de deur uit kunnen. In een ambulance nemen
                vrijwilligers ze mee naar die bijzondere plek.
              </Answer>

              <Question
                title="Inspiratie voor levenswens"
                className="col-9 col-md-6"
              >
                Wat zou u nog één keer willen doen?
              </Question>
              <Answer
                className="col-9 offset-3 col-md-6 offset-md-5"
                link={{
                  href: 'https://www.stichtingvaarwens.nl',
                  copy: 'Naar Vaarwens.nl'
                }}
              >
                “Zou ik ook even het stuurwiel vast mogen houden?" vraagt de
                dappere Rianne. “Tuurlijk schat!” roept schipper Evert.
                Beheerst, geconcentreerd en genietend vaart ze om Pampus heen en
                zet weer koers naar Amsterdam. Dit is het verhaal van Rianne,
                die met Vaarwens nog één boottocht maakte.
              </Answer>

              <Question
                title="Inspiratie voor levenswens"
                className="col-9 col-md-6"
              >
                Wat is uw ultieme droom?
              </Question>
              <Answer
                className="col-9 offset-3 col-md-6 offset-md-5"
                link={{
                  href: 'https://www.dreams4you.nl',
                  copy: 'Naar Dreams4you.nl'
                }}
              >
                Lydia wilde heel graag naar het concert van ABBA in Ahoy.
                Dreams4you hielp met het waarmaken van die droomwens. En zo
                beleefde Lydia samen met twee vriendinnen een onvergetelijke
                disco-avond.
              </Answer>
            </div>
          </div>
        </div>
      </Questions>

      <div className="container-fluid">
        <div className="row">
          <Separator />
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <Section className="col-md-7 offset-md-1" style={{ marginBottom: 0 }}>
            <Heading rank={3}>Belangrijke keuzes om te maken</Heading>
            <p>
              Iedereen die dicht bij het einde van zijn of haar leven komt,
              krijgt te maken met een aantal keuzes. Op de volgende pagina ziet
              u een overzicht van de belangrijkste.
            </p>

            <Button href="/keuzes" color="green">
              Bekijk overzicht van keuzes
            </Button>
          </Section>
        </div>
      </div>
    </>
  );
};

export default Help;
