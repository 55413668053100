import React, { Component } from 'react';
import styles from './Carousel.module.scss';

import Slider from 'react-slick';
import { ReactComponent as ArrowIcon } from './../../assets/icons/arrow-left.svg';

class Carousel extends Component {
  settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          infinite: false
        }
      }
    ]
  };

  render() {
    return (
      <div className={styles.wrapper}>
        <button
          className={styles.arrow}
          onClick={e => {
            this.slider.slickNext();
          }}
        >
          <ArrowIcon />
        </button>
        <Slider ref={s => (this.slider = s)} {...this.settings}>
          {this.props.children}
        </Slider>
      </div>
    );
  }
}

export default Carousel;
