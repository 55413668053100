import React from 'react';
import styles from './Advertorial.module.scss';

import { HashLink as Link } from 'react-router-hash-link';
import Cite from '../Quote/Cite';

const advertorial = props => {
  return (
    <div className={styles.wrapper}>
      <Link to={props.url} className={styles.link}>
        <img src={props.imageSrc} alt={props.alt} />
      </Link>
      <blockquote className={styles.quote}>
        <p>{props.quote}</p>
      </blockquote>
      <Cite cite={props.cite} age={props.age} />
    </div>
  );
};

export default advertorial;
