/**
 * useScroll React custom hook
 * Usage:
 *    const { scrollPosition, scrollDirection } = useScroll();
 */

import { useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import subscribe from 'subscribe-event';

export function useScroll() {
  const [lastScrollTop, setLastScrollTop] = useState(window.pageYOffset);
  const [scrollPosition, setScrollPosition] = useState(window.pageYOffset);
  const [scrollDirection, setScrollDirection] = useState('down');

  // Debounce callback
  const [scrollHandler] = useDebouncedCallback(
    // function
    () => {
      setScrollPosition(window.pageYOffset);
      setScrollDirection(lastScrollTop > window.pageYOffset ? 'up' : 'down');
      setLastScrollTop(window.pageYOffset);
    },
    // delay in ms
    50
  );

  useEffect(() => {
    const unsubscribe = subscribe(window, 'scroll', scrollHandler);

    return () => {
      unsubscribe();
    };
  }, [scrollHandler]);

  return {
    scrollPosition,
    scrollDirection
  };
}

export default useScroll;
