import React from 'react';
import styles from './ScrollLink.module.scss';

import { HashLink } from 'react-router-hash-link';
import { scrollTo } from './../../utils/scroll';

const scrollLink = props => {
  const classes = [styles.link];

  classes.push(props.className);

  if (props.active) {
    classes.push(styles.active);
  }

  return (
    <HashLink
      to={props.to}
      className={classes.join(' ')}
      scroll={el => scrollTo(el, 100)}
    >
      {props.children}
    </HashLink>
  );
};

export default scrollLink;
