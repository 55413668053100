import React from 'react';
import styles from './Link.module.scss';

const link = props => {
  const classes = [styles.link];

  if (props.indent) {
    classes.push(styles.indent);
  }

  classes.push(props.className);

  return (
    <div className={styles.wrapper} style={props.style}>
      <a
        href={props.href}
        className={classes.join(' ')}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.children}
      </a>
    </div>
  );
};

export default link;
