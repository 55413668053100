import React, { useState, useEffect } from 'react';
import styles from './NavigationItems.module.scss';
import { NavLink, withRouter } from 'react-router-dom';
import useMedia from 'react-use/lib/useMedia';
import { getSegments, isSubRoute } from '../../utils/routing';

const NavigationItems = props => {
  const isDesktop = useMedia('(min-width: 768px)'); // @TODO: use variable from scss file
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const classes = [styles.wrapper];

  useEffect(() => {
    // check if the current route is a subroute
    const segments = getSegments(props.location.pathname);
    const showSubmenu = isSubRoute(segments);

    if (showSubmenu) {
      setActiveSubmenu(`/${segments[0]}`);
    }
  }, [props.location]);

  const clickHandler = () => {
    setActiveSubmenu(null);

    if (props.closeHandler) {
      props.closeHandler();
    }
  };

  const toggleSubmenuHandler = (event, url) => {
    if (isDesktop) {
      return true;
    }

    event.preventDefault();
    setActiveSubmenu(url);
  };

  const isSubmenuActive = url => {
    return activeSubmenu === url;
  };

  if (activeSubmenu !== null) {
    classes.push(styles.wrapperSubmenuActive);
  }

  classes.push(props.className);

  return (
    <ul className={classes.join(' ')}>
      <li>
        <NavLink
          onClick={clickHandler}
          exact
          to="/"
          activeClassName={styles.active}
        >
          Home
        </NavLink>
      </li>
      <li>
        <NavLink
          onClick={clickHandler}
          to="/gesprek"
          activeClassName={styles.active}
        >
          Gesprek
        </NavLink>
      </li>
      <li>
        <NavLink
          onClick={clickHandler}
          to="/zorg-en-hulp"
          activeClassName={styles.active}
        >
          Zorg en hulp
        </NavLink>
      </li>
      <li>
        <NavLink
          onClick={clickHandler}
          to="/keuzes"
          activeClassName={styles.active}
        >
          Keuzes
        </NavLink>
      </li>
      <li>
        <NavLink
          onClick={clickHandler}
          to="/ervaringsverhalen"
          activeClassName={styles.active}
        >
          Verhalen
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/over"
          activeClassName={styles.active}
          className={isSubmenuActive('/over') ? styles.active : null}
          onClick={e => {
            return toggleSubmenuHandler(e, '/over');
          }}
        >
          Over palliatieve zorg
        </NavLink>
        <div className={styles.submenuWrapper}>
          <ul className={styles.submenu}>
            <li className={styles.hideOnDesktop}>
              <button
                className={styles.buttonBack}
                onClick={e => {
                  return toggleSubmenuHandler(e, null);
                }}
              >
                Terug
              </button>
            </li>
            <li className={`${styles.hideOnDesktop} ${styles.last}`}>
              <NavLink
                onClick={clickHandler}
                to="/over"
                // activeClassName={styles.active}
              >
                Over palliatieve zorg
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={clickHandler}
                to="/over/vragen"
                activeClassName={styles.active}
              >
                Vragen
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={clickHandler}
                to="/over/kosten"
                activeClassName={styles.active}
              >
                Kosten
              </NavLink>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  );
};

export default withRouter(NavigationItems);
