import React from 'react';
import styles from './CtaList.module.scss';

import Cta from './../Cta/Cta';

import IconBeslissen from './../../assets/icons/beslissen.svg';
import IconOpties from './../../assets/icons/opties.svg';
import IconCheck from './../../assets/icons/check.svg';

const ctaList = props => {
  const content = [
    { href: '/gesprek', copy: 'Zo begint u het gesprek', icon: IconBeslissen },
    {
      href: '/zorg-en-hulp',
      copy: 'Er is zorg en hulp voor u',
      icon: IconOpties
    },
    { href: 'keuzes', copy: 'Keuzes om te maken', icon: IconCheck }
  ];

  const items = content.map((item, index) => {
    return (
      <div className="col-sm-4 col-md-12 col-lg-4" key={index}>
        <Cta href={item.href} icon={item.icon}>
          {item.copy}
        </Cta>
      </div>
    );
  });

  return (
    <div className={`row ${styles.list}`} style={props.style}>
      {items}
    </div>
  );
};

export default ctaList;
