import React from 'react';
import styles from './screens.module.scss';

import storiesData from './../data/stories.json';
import quicknavData from './../data/quicknav.json';

import { Helmet } from 'react-helmet';
import Hero from './../components/Hero/Hero';
import Title from './../components/Title/Title';
import CtaList from './../components/CtaList/CtaList';
import Separator from './../components/Separator/Separator';
import IntroCopy from './../components/IntroCopy/IntroCopy';
import Quote from './../components/Quote/Quote';
import TopicList from './../components/TopicList/TopicList';
import Button from './../components/Button/Button';
import Section from './../components/Section/Section';
import aboutImage from './../assets/images/palliatieve-zorg-mannelijke-kunstenaar-in-atelier.jpg';
import mobileVisual from './../assets/images/palliatieve-zorg-vrouw-biedt-vrouw-zorg-en-hulp.jpg';
import desktopVisual from './../assets/images/palliatieve-zorg-vrouw-biedt-vrouw-zorg-en-hulp-large.jpg';
import Heading from './../components/Heading/Heading';
import Carousel from '../components/Carousel/Carousel';
import getStories from '../utils/stories';

const home = () => {
  const stories = getStories(storiesData, 2, true);

  return (
    <>
      <Helmet>
        <title>Palliatieve zorg | Home</title>
        <meta
          name="description"
          content="Palliatieve zorg is zorg voor u en uw naasten als u niet lang meer te leven heeft. Op deze site vindt u tips en links naar organisaties die u kunnen helpen."
        />
        <meta name="keywords" content="palliatieve zorg, tips, hulp" />
      </Helmet>
      <div className="container-fluid">
        <Hero>
          <Title
            title="Ook de laatste periode van uw leven vraagt om de juiste zorg."
            image={{
              src: mobileVisual,
              desktopSrc: desktopVisual,
              position: 'bottom',
              alt:
                'Vrouw die palliatieve zorg krijgt, uit wandelen met haar honden'
            }}
            className={styles.title}
            style={{ order: '0' }}
            fixedBackgroundHeight
          />
          <CtaList style={{ order: '1' }} />
          <Separator />
          <IntroCopy className={styles.introCopy} style={{ order: '2' }}>
            Als u heeft gehoord dat u ongeneeslijk ziek bent, dan kunt u rekenen
            op palliatieve zorg. Dat is zorg voor zowel u als uw naasten. Het
            doel ervan is dat u deze periode zo goed mogelijk beleeft, en u nog
            kunt doen wat u belangrijk vindt.
          </IntroCopy>
          <IntroCopy className={styles.introCopy} style={{ order: '2' }}>
            Deze site helpt u daarbij, met tips, ervaringen van anderen en links
            naar websites van organisaties die u kunnen helpen.
          </IntroCopy>
        </Hero>
      </div>
      <Separator />
      <div className="container-fluid">
        <Section className="row">
          <div className="col-md-3 offset-md-1">
            <Heading rank={2}>Er is nog tijd als uw tijd is gekomen</Heading>
            <p>
              Wat doet u met de tijd die u nog heeft? En wat is belangrijk voor
              u? Het begint met vertellen wat u nodig heeft. Zodat u in deze
              moeilijke fase nog kunt doen wat u belangrijk vindt.
            </p>
          </div>

          <div className="col-md-7 offset-md-1">
            <Carousel>
              <Quote
                icon
                quote="Het nieuws in het ziekenhuis kwam hard aan. Alhoewel ik heel positief ben ingesteld, ben ik niet zo’n prater. Een goede vriendin heeft me daarmee geholpen."
                cite="Wiljohn"
                age="43 jaar"
                button={{
                  href: `/gesprek#${quicknavData['gesprek'][0]['slug']}`,
                  copy: 'Praten met uw naasten'
                }}
                carousel
              />
              <Quote
                icon
                quote="Het is heel vreemd om je te realiseren dat je in de laatste fase van je leven bent. Ik probeer van elk moment te genieten."
                cite="Monique"
                age="66 jaar"
                button={{
                  href: `/gesprek#${quicknavData['gesprek'][2]['slug']}`,
                  copy: 'Praten over levensvragen'
                }}
                carousel
              />
              <Quote
                icon
                quote="Als mijn leven afgelopen is, dan is dat zo. Mijn laatste dagen heb ik uitgebreid gepland samen met mijn kinderen. Ook mijn huisarts is op de hoogte; die heb ik een coördinerende rol gegeven."
                cite="Els"
                age="63 jaar"
                button={{
                  href: `/gesprek#${quicknavData['gesprek'][1]['slug']}`,
                  copy: 'Praten met uw huisarts'
                }}
                carousel
              />
            </Carousel>
          </div>
        </Section>
      </div>
      <Separator />
      <div className="container-fluid">
        <TopicList />
      </div>
      <Separator />
      <div className="container-fluid">
        <Section className="row">
          <div className="col-md-3">
            <Heading rank={2}>Zij vertellen hun verhaal</Heading>
            <p>
              Hoe denken anderen over de tijd die ze nog hebben? En welke rol
              speelt palliatieve zorg daarin?
            </p>
            <Button color="green" href="/ervaringsverhalen">
              Lees hun verhalen
            </Button>
          </div>
          <div className="col-sm-6 col-md-4 offset-md-1 col-lg-4">
            {stories[0]}
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">{stories[1]}</div>
        </Section>
      </div>
      <Separator />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 offset-md-1">
            <Section className={styles.about} intro>
              <img
                className={styles.aboutImage}
                src={aboutImage}
                alt="Over palliatieve zorg"
              />
              <Heading rank={3}>Over palliatieve zorg</Heading>
              <p>
                Palliatieve zorg is de zorg die u krijgt als genezen niet meer
                mogelijk is. Het woord komt van het Latijnse ‘pallium’:
                ‘mantel’. Het doel is dan ook dat de zorg u omringt als een
                mantel. Er is palliatieve zorg voor volwassenen, kinderen en
                speciale doelgroepen.
              </p>
              <Button href="/over" color="green">
                Meer over palliatieve zorg
              </Button>
            </Section>
          </div>

          <div className="col-md-5 offset-md-1">
            <Section color="pink" float="right" style={{ marginBottom: 0 }}>
              <Heading rank={3}>Waar kunt u terecht met vragen</Heading>
              <p>
                U krijgt in deze periode zorg van verschillende organisaties.
                Dat betekent dat het misschien niet altijd meteen duidelijk is
                bij wie u moet zijn als u hier vragen over heeft. We leggen in
                grote lijnen uit waar u terecht kunt.
              </p>
              <Button href="/over/vragen">
                Vragen over palliatieve zorg en hulp
              </Button>
            </Section>
          </div>
        </div>
      </div>
    </>
  );
};

export default home;
