import React from 'react';
import styles from './Questions.module.scss';

const questions = props => {
  return (
    <dl className={`${styles.wrapper} ${props.className}`}>{props.children}</dl>
  );
};

export default questions;
