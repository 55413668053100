import React, { useState } from 'react';
import styles from './Layout.module.scss';
import useScroll from './../../hooks/useScroll';
import { scrolledPastEl } from './../../utils/scroll';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import quickNavData from './../../data/quicknav.json';

import Header from './../Header/Header';
import SideDrawer from './../SideDrawer/SideDrawer';
import Footer from './../Footer/Footer';
import BreakpointLabel from './../BreakpointLabel/BreakpointLabel';
import SubNav from './../SubNav/SubNav';

const Layout = props => {
  const path = props.location.pathname.slice(1);
  const { scrollPosition, scrollDirection } = useScroll();
  const [sideDrawerIsVisible, setSideDrawerIsVisible] = useState(false);
  const showSubNav = scrolledPastEl('quicknav', scrollPosition);
  const showHeader = scrollDirection === 'up' || scrollPosition <= 70;
  const minimizeHeader = scrollPosition >= 70;

  let mainClasses = [styles.main];
  let navClasses = [styles.nav];

  let subnav;
  let breakpointLabel;

  const sideDrawerClosedHandler = () => {
    setSideDrawerIsVisible(false);
  };

  const sideDrawerToggleHandler = () => {
    setSideDrawerIsVisible(!sideDrawerIsVisible);
  };

  if (showHeader) {
    mainClasses = [styles.main, 'showHeader'];
  } else {
    navClasses = [styles.nav, styles.hideNav];
  }

  if (minimizeHeader) {
    navClasses = [styles.nav, 'minimizeHeader'];
  }

  if (quickNavData[path]) {
    subnav = <SubNav show={showSubNav} items={quickNavData[path]} />;
  }

  if (process.env.NODE_ENV !== 'production') {
    breakpointLabel = <BreakpointLabel />;
  }

  return (
    <>
      <Helmet defaultTitle="Palliatieve zorg" />
      <SideDrawer
        show={sideDrawerIsVisible}
        closeHandler={sideDrawerClosedHandler}
      />
      <nav className={navClasses.join(' ')}>
        <Header drawerToggleHandler={sideDrawerToggleHandler} />
        {subnav}
      </nav>
      <div className={mainClasses.join(' ')}>{props.children}</div>
      <Footer />
      {breakpointLabel}
    </>
  );
};

export default withRouter(Layout);
