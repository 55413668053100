import React from 'react';
import styles from './QuickNav.module.scss';

import { withRouter } from 'react-router-dom';
import Heading from './../Heading/Heading';
import ScrollLink from './../ScrollLink/ScrollLink';

import quicknavData from './../../data/quicknav.json';

const quickNav = props => {
  let heading;

  const current = props.currentIndex ? props.currentIndex : 0;
  const path = props.location.pathname.slice(1);

  if (!quicknavData[path]) {
    return false;
  }

  if (!props.noHeading) {
    heading = (
      <Heading rank={2} className="h3" style={{ marginBottom: '16px' }}>
        {props.title || 'Snel naar...'}
      </Heading>
    );
  }

  const items = quicknavData[path].map((item, index) => {
    return (
      <li key={index}>
        <ScrollLink
          to={`${props.location.pathname}#${item.slug}`}
          active={index === current}
        >
          {item.copy}
        </ScrollLink>
      </li>
    );
  });

  return (
    <div id="quicknav" className={styles.wrapper}>
      {heading}
      <ul className={styles.list}>{items}</ul>
    </div>
  );
};

export default withRouter(quickNav);
