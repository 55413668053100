import React from 'react';
import styles from './screens.module.scss';

import Title from './../components/Title/Title';
import Separator from './../components/Separator/Separator';
import IntroCopy from './../components/IntroCopy/IntroCopy';
import Section from './../components/Section/Section';
import Heading from './../components/Heading/Heading';
import Link from './../components/Link/Link';

const home = () => {
  return (
    <>
      <div className="container-fluid">
        <Section>
          <div className={`row ${styles.background}`}>
            <div className="col-md-8">
              <Title title="Cookieverklaring" />
              <IntroCopy>
                Wij maken op onze Website gebruik van cookies om het gebruik van
                de Website voor jou makkelijker te maken. Een cookie is een
                eenvoudig klein bestandje dat met pagina's van de Website wordt
                meegestuurd en door jouw browser op de harde schrijf van je
                computer wordt opgeslagen. De daarin opgeslagen informatie kan
                bij een volgend bezoek weer naar onze servers teruggestuurd
                worden.
              </IntroCopy>
            </div>
          </div>
        </Section>

        <Separator />

        <div className="row">
          <div className="col-md-7 offset-md-2">
            <Section>
              <Heading rank={2}>Cookies accepteren</Heading>
              <p>
                Onze Website functioneert het beste als je de cookies
                accepteert. Je kunt onze cookies eenvoudig accepteren in de
                cookiemelding die tijdens je eerste bezoek aan de website
                verschijnt.
              </p>
            </Section>
            <Section>
              <Heading rank={2}>Cookies verwijderen of uitschakelen</Heading>
              <p>
                Je kunt je afmelden voor cookies door je internetbrowser zo in
                te stellen dat deze geen cookies meer opslaat. Daarnaast kun je
                ook alle informatie die eerder is opgeslagen via de instellingen
                van je browser verwijderen. Zie voor een toelichting:{' '}
                <Link href="https://veiliginternetten.nl/themes/situatie/wat-zijn-cookies/">
                  Veilig internetten
                </Link>
              </p>
            </Section>
            <Section>
              <Heading rank={2}>Noodzakelijk/functionele cookies</Heading>
              <p>
                Deze cookies zijn noodzakelijk voor een goede werking van de
                Website.
              </p>
            </Section>
            <Section>
              <Heading rank={2}>Analytische cookies</Heading>
              <p>
                De analytische cookies verzamelen informatie over hoe bezoekers
                de Website gebruiken. Deze informatie helpt ons om de Website te
                verbeteren, waardoor deze beter aansluit op jouw wensen. Wij
                gebruiken hiervoor Google Analytics om geanonimiseerde
                overzichten te maken van het bezoekersverkeer op onze Website.
              </p>
            </Section>
            <Section>
              <Heading rank={2}>Cookies van derden</Heading>
              <p>
                Onze website bevat cookies van derden middels bijvoorbeeld
                YouTube video’s, google plattegronden of share-buttons voor
                social media. Deze partijen plaatsen cookies wanneer je gebruikt
                maakt van de service en/of ingelogd bent bij deze partijen. Om
                te weten hoe zij met jouw privacy omgaan, lees de
                privacyverklaring van Google, Youtube, etc.
              </p>
            </Section>
          </div>
        </div>
      </div>
    </>
  );
};

export default home;
