import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import smoothscroll from 'smoothscroll-polyfill';
import 'what-input';
import './setupTests.js';

import React from 'react';
import { Router } from 'react-router-dom';
import { render } from 'react-snapshot';
import './assets/styles/global.scss';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import ReactPiwik from 'react-piwik';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

// Scroll polyfill
smoothscroll.polyfill();

const piwik = new ReactPiwik({
  url: 'https://statistiek.rijksoverheid.nl/piwik',
  siteId: 1839,
  trackErrors: true
});

ReactPiwik.push(['setCookieDomain', '*.overpalliatievezorg.nl']);
ReactPiwik.push(['setDomains', ['*.overpalliatievezorg.nl']]);
ReactPiwik.push(['enableHeartBeatTimer', 10]);
ReactPiwik.push(['setLinkTrackingTimer', 750]);
ReactPiwik.push(['trackPageView']);
// ReactPiwik.push(['enableLinkTracking']);

const app = (
  <Router history={piwik.connectToHistory(history)}>
    <ScrollToTop>
      <App />
    </ScrollToTop>
  </Router>
);

render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();