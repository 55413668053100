import React, { useEffect } from 'react';
import styles from './screens.module.scss';

import Hero from './../components/Hero/Hero';
import Title from './../components/Title/Title';
// import titleStyles from './../components/Title/Title.module.scss';
import Separator from './../components/Separator/Separator';
import IntroCopy from './../components/IntroCopy/IntroCopy';
import Quote from './../components/Quote/Quote';
import QuickNav from './../components/QuickNav/QuickNav';
import Section from './../components/Section/Section';
import mobileVisual from './../assets/images/man-die-palliatieve-zorg-krijgt-zit-thuis-achter-zijn-computer.jpg';
import desktopVisual from './../assets/images/man-die-palliatieve-zorg-krijgt-zit-thuis-achter-zijn-computer-large.jpg';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import Panel from './../components/Panel/Panel';
import Link from './../components/Link/Link';
import Heading from './../components/Heading/Heading';
import Button from './../components/Button/Button';
import { Helmet } from 'react-helmet';
import * as Stickyfill from 'stickyfilljs';

const Choices = () => {
  useEffect(() => {
    const elements = document.querySelectorAll('.sticky');
    Stickyfill.add(elements);
  }, []);

  return (
    <>
      <Helmet>
        <title>Belangrijke keuzes om te maken | Palliatieve zorg</title>
        <meta
          name="description"
          content="Als uw overlijden dichterbij komt, krijgt u te maken met bepaalde keuzes. Over reanimatie en palliatieve sedatie bijvoorbeeld. En over uw testament en uitvaart."
        />
        <meta
          name="keywords"
          content="palliatieve sedatie, reanimatie, euthanasie"
        />
      </Helmet>
      <div className="container-fluid">
        <Hero>
          <Title
            title="Belangrijke keuzes"
            fixedBackgroundHeight
            image={{
              src: mobileVisual,
              desktopSrc: desktopVisual,
              position: 'top',
              alt:
                'Vrouw die palliatieve zorg krijgt, schildert in haar zolder atelier'
            }}
          />
          <IntroCopy>
            Het is belangrijk dat u, nu het nog kan, een aantal keuzes
            overweegt. Waar u wilt zijn in uw laatste levensdagen bijvoorbeeld.
            Maar ook of u gereanimeerd wilt worden. De tips hieronder helpen u
            om deze keuzes te maken.
          </IntroCopy>

          <QuickNav />
        </Hero>

        <Separator />

        <Section intro>
          <div className="row" id="begin-klein">
            <div className="col offset-md-1">
              <Heading rank={2}>Begin klein</Heading>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 offset-md-1">
              <p>
                Het is heel begrijpelijk als u het moeilijk vindt om na te
                denken over het einde van uw leven. U hoeft ook niet alles in
                één keer te besluiten. Lees in elk geval de keuzes hieronder op
                uw gemak door. Dan heeft u alvast een begin gemaakt.
              </p>
            </div>

            <div className="col-md-4 offset-md-1">
              <Heading rank={3} className="h4" color="green">
                Betrek uw omgeving bij uw keuzes
              </Heading>
              <p>
                U mag natuurlijk zelf kiezen wat u wilt in deze laatste fase van
                uw leven. Maar u hoeft er niet alleen over na te denken. Uw
                huisarts kan u helpen. Hij of zij weet ook wat er in uw situatie
                mogelijk is. Betrek ook uw familie of vrienden bij uw keuzes,
                als dat kan.
              </p>
              <Button href="/gesprek" color="green">
                Zo begint u het gesprek
              </Button>
            </div>
          </div>
        </Section>

        <Separator />

        <Section className="row" id="keuzes-over-uw-zorg">
          <div className={`col-md-3 offset-md-1 sticky ${styles.sticky}`}>
            <Heading rank={2}>Keuzes over uw zorg</Heading>
            <p>
              Het helpt als u op tijd over deze keuzes nadenkt, en uw artsen en
              dierbaren vertelt wat u wilt. Het doel van palliatieve zorg is
              namelijk dat u zelf zo weinig mogelijk zorgen heeft. Als anderen
              op tijd weten wat u wilt, weten zij hoe ze u kunnen helpen.
            </p>
          </div>

          <div className="col-md-6 offset-md-1">
            <Accordion
              allowZeroExpanded={true}
              allowMultipleExpanded={true}
              preExpanded={[0]}
            >
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span className="title">Plek van overlijden kiezen</span>
                    <p className="intro">
                      Een belangrijke keuze is waar u in de laatste periode van
                      uw leven wilt zijn, en daarmee ook waar u wilt overlijden.
                    </p>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Panel>
                    <p>
                      U bepaalt zelf waar u het einde van uw leven wilt
                      doorbrengen. Uw zorgverleners en uw naasten hebben hierin
                      ook een stem. Zij kunnen aangeven wat volgens hen het
                      beste is. Het belangrijkste is dat u op een plek bent waar
                      u zich prettig voelt.
                    </p>

                    <p>
                      Veel mensen willen thuis sterven, in hun eigen vertrouwde
                      omgeving. Als u hiervoor kiest, krijgt u hulp van de
                      huisarts en de thuiszorg. Sommige mensen willen juist niet
                      thuis blijven. Ze zijn bijvoorbeeld bang dat dat te zwaar
                      is voor hun naasten.
                    </p>
                    <Link
                      indent
                      href="https://www.kiesbeter.nl/artikelen/zorgthemas/palliatieve-zorg/palliatieve-terminale-zorg-ptz"
                    >
                      Thuis, hospice of verpleeghuis
                    </Link>
                  </Panel>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span className="title">Reanimeren, ja of nee</span>
                    <p className="intro">
                      Als uw hartslag ophoudt, kan uw arts of zorgverlener
                      proberen deze weer op gang te krijgen. U kunt zelf kiezen
                      of u dat wilt of niet. Het kan lastig zijn om dit te
                      bespreken. Toch is het belangrijk dat uw huisarts en in
                      elk geval één vertrouwd persoon weten wat u graag wilt.
                    </p>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Panel title="Penning">
                    <p>
                      Als u niet gereanimeerd wilt worden, kunt u het beste een
                      niet reanimeren-verklaring dragen. Dit kan een penning
                      zijn, een kaartje of een tatoeage. Als een arts dit ziet,
                      zal hij of zij geen reanimatie starten.
                    </p>
                    <p>
                      Het is belangrijk om uw wensen te bespreken met uw
                      huisarts en een van uw naasten, zodat zij op de hoogte
                      zijn. U mag altijd uw verklaring veranderen. Breng ook dan
                      weer uw omgeving op de hoogte.
                    </p>
                    <Link
                      indent
                      href="https://www.patientenfederatie.nl/producten/niet-reanimerenpenning/de-niet-reanimerenpenning"
                    >
                      Niet-reanimerenpenning bestellen
                    </Link>
                  </Panel>
                </AccordionItemPanel>
              </AccordionItem>
              <Separator />
              <Quote
                className={styles.accordionQuote}
                icon
                quote="Ik probeer alles daarom zo goed mogelijk te regelen. Een week of vier geleden heb ik adressenlijsten gemaakt voor mijn begrafenis en opgeschreven wat mijn wensen zijn."
                cite="Monique"
                age="66 jaar"
              />
              <Separator />
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span className="title">Vertegenwoordiger aanwijzen</span>
                    <p className="intro">
                      Een vertegenwoordiger is iemand die keuzes over uw
                      medische zorg kan maken als u dat zelf niet meer kunt.
                    </p>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Panel>
                    <p>
                      Vaak is de vertegenwoordiger een partner of familielid. U
                      kunt aan de hand van de pagina over de wilsverklaring
                      bespreken wat u wel en niet wilt. Soms moet u uw
                      vertegenwoordiger officieel aanwijzen.
                    </p>
                    <Link
                      indent
                      href="https://www.kiesbeter.nl/artikelen/zorgthemas/palliatieve-zorg/wilsverklaring-bij-naderend-levenseinde"
                    >
                      Wilsverklaring: kijk op kiesbeter.nl
                    </Link>
                    <Link
                      indent
                      href="https://www.goedvertegenwoordigd.nl/verder-wegwijs/informatie-en-vragen/"
                    >
                      Goedvertegenwoordigd.nl
                    </Link>
                  </Panel>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span className="title">Euthanasie afwegen</span>
                    <p className="intro">
                      Als u ondraaglijk lijdt en niet meer verder wilt leven,
                      kunt u uw arts vragen om euthanasie: het beëindigen van uw
                      leven door medicijnen. Dit gebeurt alleen na zorgvuldig
                      overleg tussen u en meerdere artsen.
                    </p>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Panel>
                    <p>
                      De arts zal uitgebreid met u overleggen over uw verzoek om
                      euthanasie. Hij of zij zal met u praten over hoe u uw
                      lijden ervaart, en of er niets anders meer mogelijk is om
                      uw lijden te verlichten. Ook zal de arts met u bespreken
                      of de wens echt van uzelf komt. U spreekt ook een tweede
                      arts.
                    </p>
                    <p>
                      Een verzoek om euthanasie doet u bij uw huisarts. De arts
                      hoeft hier geen 'ja' op te zeggen, ook niet als uw verzoek
                      volgens de richtlijnen is. De arts kan namelijk ook
                      persoonlijke bezwaren hebben, bijvoorbeeld omdat het niet
                      mag volgens zijn of haar geloof. In dat geval kunt u om
                      een andere arts vragen.
                    </p>
                    <Link
                      indent
                      href="https://www.thuisarts.nl/levenseinde/ik-denk-aan-euthanasie"
                    >
                      Meer over euthanasie op Thuisarts.nl
                    </Link>
                    <Link
                      indent
                      href="https://www.rijksoverheid.nl/onderwerpen/levenseinde-en-euthanasie/euthanasie"
                    >
                      Euthanasiewet: uitleg
                    </Link>
                    <Link
                      indent
                      href="https://www.rijksoverheid.nl/onderwerpen/levenseinde-en-euthanasie/zorgvuldigheidseisen"
                    >
                      Euthanasiewet: zorgvuldigheidseisen
                    </Link>
                  </Panel>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span className="title">Palliatieve sedatie</span>
                    <p className="intro">
                      Palliatieve sedatie is het verlagen van het bewustzijn in
                      de laatste levensfase, met als doel om lijden te
                      verlichten. Afhankelijk van de situatie kan dit een lichte
                      slaperigheid zijn tot een diepe slaap.
                    </p>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Panel>
                    <p>
                      Het woord 'palliatief' betekent 'mantel' in het Latijn.
                      Het doel van palliatieve zorg is dan ook om iemand te
                      omringen met een mantel van zorg. Het woord 'sedatie'
                      betekent 'verdoving'. Bij palliatieve sedatie krijgt
                      iemand verdovende medicatie om pijn en benauwdheid te
                      verlichten en minder bij bewustzijn te zijn.{' '}
                    </p>

                    <strong>Verschil met euthanasie</strong>
                    <p>
                      Het doel van palliatieve sedatie is niet om het leven te
                      verkorten. Dit is dan ook geen euthanasie, want euthanasie
                      is wel bedoeld om het leven te beëindigen.
                    </p>
                    <Link
                      indent
                      href="https://shop.iknl.nl/shop/folder-palliatieve-sedatie/54895"
                    >
                      Folder: wat is palliatieve sedatie
                    </Link>
                  </Panel>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span className="title">Stoppen met voeding en vocht</span>
                    <p className="intro">
                      Als u ongeneeslijk ziek bent, kunt u het moment van
                      sterven eerder laten komen door te stoppen met eten en
                      drinken. Dit kan een keuze zijn die bij u past, omdat u zo
                      de regie over uw levenseinde in eigen hand houdt.
                    </p>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Panel>
                    <p>
                      Niet meer eten en drinken om het levenseinde te versnellen
                      geeft een natuurlijke dood. Het is dus geen zelfdoding of
                      euthanasie.
                    </p>
                    <p>
                      U heeft vaak vanzelf al minder honger en dorst als u
                      ernstig ziek bent. In de brochure voor mantelzorgers over
                      vocht en voeding staat meer hierover.
                    </p>
                    <Link
                      indent
                      href="https://www.netwerkpalliatievezorg.nl/Portals/139/IKW_Vocht_en_voeding_2010.pdf"
                    >
                      Folder over vocht en voeding
                    </Link>
                    <p>
                      U bent de enige die dit besluit kan nemen. U kunt er
                      altijd op terugkomen. Het is belangrijk dat u uw wensen
                      bespreekt met uw naasten, de arts en verpleegkundige. Ook
                      is het goed om ze op papier te zetten in een
                      wilsverklaring. Dan weet uw omgeving wat u wilt, ook als u
                      dat zelf niet meer kunt zeggen.
                    </p>
                    <Link
                      indent
                      href="https://www.jeroenboschziekenhuis.nl/sites/default/files/documents/2018-12/ALG-105%20Bewust%20stoppen%20met%20eten%20en%20drinken%20om%20het%20levenseinde%20te%20versnellen.pdf"
                    >
                      Levenseinde versnellen
                    </Link>
                  </Panel>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </Section>

        <Separator />

        <Section className="row">
          <div className="col-md-5">
            <Accordion
              allowMultipleExpanded={true}
              allowZeroExpanded={true}
              preExpanded={[0]}
            >
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span className="title">
                      Wilsverklaring over uw levenseinde
                    </span>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Een wilsverklaring maken betekent dat u op papier zet wat u
                    wel en niet wilt dat er gebeurt als u bijna overlijdt.
                    Reanimatie na een hartstilstand bijvoorbeeld. Of euthanasie.
                    Deze verklaring geeft u aan uw huisarts. Er zijn per persoon
                    verschillende keuzes om te overwegen. De huisarts helpt u
                    daarbij.
                  </p>
                  <Link
                    indent
                    href="https://www.rijksoverheid.nl/onderwerpen/levenseinde-en-euthanasie/vraag-en-antwoord/wilsverklaring-opstellen"
                  >
                    Wilsverklaring opstellen: rijksoverheid.nl
                  </Link>
                  <Link
                    indent
                    href="https://www.thuisarts.nl/levenseinde/ik-wil-wensen-voor-mijn-levenseinde-vastleggen"
                  >
                    Wensen vastleggen: thuisarts.nl
                  </Link>
                  <Link indent href="https://www.thuisarts.nl/levenseinde">
                    Levenseinde: thuisarts.nl
                  </Link>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span className="title">Orgaandonor worden</span>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Vanaf juli 2020 gaat de nieuwe Donorwet in. De overheid
                    vraagt daarom iedereen het Donorregister in te vullen of zij
                    wel of niet organen en weefsels aan een patiënt willen geven
                    na hun overlijden. Als u het nog niet weet is het belangrijk
                    erover na te denken. U kunt er met uw familie of vrienden
                    over praten. Als u het weet, vul dan uw keuze in het
                    Donorregister in. Als u er later anders over denkt, dan kunt
                    u altijd uw keuze veranderen.
                  </p>

                  <Link
                    indent
                    href="https://www.donorregister.nl/uw-keuze-vastleggen/hoe-legt-u-uw-keuze-vast"
                  >
                    Donorregister: zo legt u uw keuze vast
                  </Link>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span className="title">Uitvaartwensen opschrijven</span>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Voor de ene persoon is het genoeg om te kiezen tussen
                    cremeren of begraven. De ander wil juist tot in detail
                    keuzes maken over de uitvaart. Er komt veel op uw
                    nabestaanden af in de week na uw overlijden. U kunt hen een
                    hoop twijfels besparen door in elk geval een paar wensen op
                    te schrijven.
                  </p>

                  <Link
                    indent
                    href="https://palliatievezorg.nl/levenseinde/uitvaart/"
                  >
                    Uitvaart: Deze keuzes kunt u maken
                  </Link>
                  <Link
                    indent
                    href="https://www.rijksoverheid.nl/onderwerpen/overlijden/vraag-en-antwoord/overlijden-hoe-bereid-ik-me-voor-op-mijn-eigen-overlijden"
                  >
                    Meer praktische tips voor u
                  </Link>
                  <Link
                    indent
                    href="https://www.rijksoverheid.nl/onderwerpen/overlijden"
                  >
                    Praktische tips voor uw dierbaren
                  </Link>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span className="title">Testament maken</span>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Het kan best overweldigend zijn om na te denken over wat u
                    wilt nalaten, en aan wie. Maar door deze zaken nu te
                    regelen, voorkomt u eventuele latere onduidelijkheden. Op
                    notaris.nl kunt u een brochure downloaden met heldere
                    uitleg.
                  </p>

                  <Link
                    indent
                    href="https://www.notaris.nl/files/Brochures/brochure-hoe-regel-ik-mijn-nalatenschap.pdf?38153bfbc8"
                  >
                    Brochure: nalatenschap regelen
                  </Link>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>

          <div
            className={`order-first col-md-4 offset-md-1 order-md-1 ${
              styles.sticky
            }`}
            id="keuzes-op-praktisch-gebied"
          >
            <Section>
              <Heading rank={2}>Keuzes op praktisch gebied</Heading>
              <p>
                Als u de keuzes over uw medische zorg heeft gemaakt, dan is het
                ook nog goed om na te denken over een aantal andere dingen.
                Orgaandonatie bijvoorbeeld, en uw nalatenschap en uitvaart. Ook
                kunt u uw keuzes over uw levenseinde vastleggen in een
                wilsverklaring.
              </p>
            </Section>
          </div>
        </Section>

        <Separator />
      </div>

      <div className="row bg-right bg-pink">
        <div className="col">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-7 offset-md-5">
                <Section color="pink" style={{ marginBottom: 0 }} float="right">
                  <Heading rank={3}>
                    Zorg en hulp voor u en uw dierbaren
                  </Heading>
                  <p>
                    Wat heeft u nodig om u in deze periode zo goed mogelijk te
                    voelen? Er staan professionals en vrijwilligers klaar om u
                    te helpen.
                  </p>

                  <Button href="/zorg-en-hulp">
                    Er is zorg en hulp voor u
                  </Button>
                </Section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Choices;
