import React from 'react';

import Section from './../components/Section/Section';
import VideoThumb from './../components/VideoThumb/VideoThumb';
import Advertorial from './../components/Advertorial/Advertorial';

import chunk from './../utils/chunk';

export const getStories = (data, limit = 99, isCarousel) => {
  let stories = data.slice(0, limit).map((item, i) => {
    let wrapperClasses = '';

    if (!isCarousel) {
      wrapperClasses += `col-sm-6 col-md-4`;
    }

    switch (item.type) {
      case 'article': {
        return (
          <div className={wrapperClasses} key={`item-${i}`}>
            <Advertorial
              imageSrc={process.env.PUBLIC_URL + item.thumbnail}
              alt={item.alt}
              url={`/ervaringsverhalen/${item.slug}`}
              quote={item.quote}
              cite={item.cite}
              age={item.age}
            />
          </div>
        );
      }
      case 'video':
      default: {
        return (
          <div className={wrapperClasses} key={`item-${i}`}>
            <VideoThumb
              imageSrc={process.env.PUBLIC_URL + item.thumbnail}
              videoSrc={item.videoSrc}
              alt={item.alt}
              quote={item.quote}
              cite={item.cite}
              age={item.age}
            />
          </div>
        );
      }
    }
  });

  if (!isCarousel) {
    const chunks = chunk(stories, 3);

    return chunks.map((chunk, i) => {
      return (
        <Section key={`stories-${i}`} className="row">
          {chunk}
        </Section>
      );
    });
  }

  return stories;
};

export default getStories;
