import React from 'react';
import styles from './Panel.module.scss';

const panel = props => {
  let title;

  if (props.title) {
    title = <div className={styles.title}>{props.title}</div>;
  }

  return (
    <div className={styles.wrapper}>
      {title}
      <div className={styles.content}>{props.children}</div>
    </div>
  );
};

export default panel;
