import React from 'react';
import styles from './screens.module.scss';

import Hero from './../components/Hero/Hero';
import Title from './../components/Title/Title';
import Separator from './../components/Separator/Separator';
import IntroCopy from './../components/IntroCopy/IntroCopy';
import QuickNav from './../components/QuickNav/QuickNav';
import mobileVisual from './../assets/images/palliatieve-zorg-mannelijke-kunstenaar-in-atelier.jpg';
import desktopVisual from './../assets/images/palliatieve-zorg-mannelijke-kunstenaar-in-atelier-large.jpg';
import Section from './../components/Section/Section';
import Heading from './../components/Heading/Heading';
import Button from './../components/Button/Button';
import Link from './../components/Link/Link';
import { Helmet } from 'react-helmet';

const home = () => {
  return (
    <>
      <Helmet>
        <title>Over palliatieve zorg | Palliatieve zorg</title>
        <meta
          name="description"
          content="Palliatieve zorg krijgt u als u ongeneeslijk of terminaal ziek bent. Hier vindt u tips over zorg voor migranten en kinderen en over kosten en vergoeding."
        />
        <meta
          name="keywords"
          content="palliatieve zorg, migranten, kinderen, kosten"
        />
      </Helmet>
      <div className="container-fluid">
        <Hero>
          <Title
            title="Over palliatieve zorg"
            fixedBackgroundHeight
            image={{
              src: mobileVisual,
              desktopSrc: desktopVisual,
              position: 'top',
              alt: 'Mannelijke kunstenaar zit in zijn atelier'
            }}
          />
          <IntroCopy>
            Als u heeft gehoord dat u ongeneeslijk ziek bent, dan kunt u rekenen
            op zorg en hulp. Dat heet palliatieve zorg: zorg die niet is gericht
            op genezen, maar op kwaliteit van leven. Zodat u de dingen kunt doen
            die u belangrijk vindt, in de tijd die u nog heeft.
          </IntroCopy>

          <QuickNav />
        </Hero>

        <Separator />

        <div className="row" id="wat-en-waarom">
          <div className="col-md-4 offset-md-1">
            <Section>
              <Heading rank={2}>Wat is palliatieve zorg?</Heading>
              <Heading rank={3} className="h4" color="green">
                Het gaat om wat u nodig heeft
              </Heading>
              <p>
                Als genezen niet meer mogelijk is, krijgt u zorg die erop is
                gericht om klachten en problemen zo veel mogelijk te voorkomen
                of verminderen. Dit kan variëren van medicijnen tegen pijn of
                misselijkheid tot psychische hulp of thuiszorg.
              </p>

              <Heading rank={3} className="h4" color="green">
                Op tijd en zo lang als het duurt
              </Heading>
              <p>
                Palliatieve zorg duurt soms dagen, soms weken en soms een jaar
                of langer. Het gaat dus niet alleen om zorg in de laatste weken
                of maanden van uw leven (dit heet ook wel de 'terminale' fase).
                U krijgt palliatieve zorg vanaf het moment dat u hoort dat u
                niet meer beter wordt. De zorg is ook bedoeld voor uw familie en
                vrienden.
              </p>
            </Section>
          </div>
          <div className="col-md-5 offset-md-2">
            <Section>
              <Heading rank={2}>Waarom is palliatieve zorg belangrijk?</Heading>
              <p>
                Goede palliatieve zorg is belangrijk, voor zowel u als uw
                dierbaren. Uit onderzoek blijkt dat patiënten die goede
                palliatieve zorg krijgen zich beter voelen in de laatste fase
                van hun leven:
              </p>
              <ul>
                <li>Ze zijn vaak minder somber en angstig.</li>
                <li>Ze hebben minder last van andere klachten en problemen.</li>
                <li>Ze ervaren een betere kwaliteit van leven.</li>
                <li>Ze hoeven minder vaak (plotseling) naar het ziekenhuis.</li>
                <li>Hun dierbaren kunnen het verlies beter verwerken.</li>
              </ul>
            </Section>
          </div>
        </div>

        <Separator />

        <div className="row" id="hoe">
          <div className="col-md-5">
            <Section intro>
              <Heading rank={2}>Hoe krijgt u palliatieve zorg?</Heading>
              <p>
                Het doel van palliatieve zorg is dat u deze laatste periode van
                uw leven zo goed mogelijk ervaart. Bespreek daarom met uw
                dierbaren en uw huisarts wat dat voor u betekent, en wat u nodig
                heeft.
              </p>
            </Section>
          </div>
        </div>

        <Section>
          <div className="row">
            <div className="col-md-4">
              <Section
                color="pink"
                button={{
                  href: '/gesprek',
                  copy: 'Tips om het gesprek te beginnen'
                }}
              >
                <Heading rank={3} className="h4">
                  Praten met uw naasten
                </Heading>
                <p>
                  Praten over de dood: daar moeten veel mensen een drempel voor
                  overwinnen. Toch is het goed om het te doen. U kunt zich
                  hierdoor omringen met hulp, en uw leven goed afsluiten. Ook
                  geeft u uw dierbaren de kans om zich voor te bereiden op het
                  afscheid.
                </p>
              </Section>
            </div>

            <div className="col-md-4">
              <Section
                color="pink"
                button={{
                  href: '/gesprek#praten-met-uw-huisarts',
                  copy: 'Tips voor overleg met de huisarts'
                }}
              >
                <Heading rank={3} className="h4">
                  Praten met de huisarts
                </Heading>
                <p>
                  Uw huisarts is vaak degene die alle zorg voor u regelt. Als u
                  heeft gehoord dat u niet meer beter wordt, dan zorgt de
                  huisarts ervoor dat u de hulp krijgt die u nodig heeft. Het is
                  dan ook een goed idee om eerst naar de huisarts te gaan om uw
                  situatie en uw behoeften te bespreken.
                </p>
              </Section>
            </div>

            <div className="col-md-4">
              <Section color="pink">
                <Heading rank={3} className="h4">
                  Meer lezen in e-book
                </Heading>
                <p>
                  Eind 2017 hebben vertegenwoordigers van patiënten, naasten,
                  ouderen, zorgverleners en zorgverzekeraars afspraken gemaakt
                  over de kwaliteit van palliatieve zorg in Nederland. In het
                  e-book Palliatieve zorg leest u een samenvatting van die
                  afspraken, gericht op u en uw naasten.
                </p>
                <Link
                  indent
                  href="https://palliatievezorg.patientenfederatie.nl"
                >
                  Download e-book Palliatieve Zorg
                </Link>
              </Section>
            </div>
          </div>
        </Section>

        <Separator />

        <div className="row" id="migranten-en-kinderen">
          <div className="col-md-4 offset-md-1">
            <Section>
              <Heading rank={2}>Palliatieve zorg voor migranten</Heading>
              <p>
                Mensen uit niet-westerse landen maken in Nederland minder
                gebruik van zorg en hulp, terwijl ze daar wel behoefte aan
                hebben. Vooral rond de laatste levensfase speelt ook wantrouwen
                een rol. In veel culturen ligt er een taboe op het spreken over
                het naderend sterven. In Nederland wordt er soms juist heel
                direct over gepraat.
              </p>

              <p>
                Toch is het belangrijk dat ook mensen met een
                migratieachtergrond palliatieve zorg krijgen, met respect voor
                hun wensen. De organisatie Pharos zet zich in voor gezondheid en
                kwaliteit van zorg voor iedereen. Op de website van Pharos staan
                filmpjes waarin herkenbare dilemma's aan bod komen.
              </p>

              <Link indent href="https://ingesprek.pharos.nl/">
                Video's: in gesprek
              </Link>
              <Link indent href="https://www.netwerknoom.nl/levenseinde/">
                NOOM: organisaties voor oudere migranten
              </Link>
              <Link
                indent
                href="https://www.huisarts-migrant.nl/zelforganisaties-page/"
              >
                Patiëntenorganisaties voor migranten
              </Link>
            </Section>
          </div>
          <div className="col-md-5 offset-md-2">
            <Section>
              <Heading rank={2}>
                Palliatieve zorg voor kinderen en hun ouders
              </Heading>
              <p>
                Ook als uw kind ongeneeslijk ziek is, kunt u natuurlijk rekenen
                op hulp van artsen, verpleegkundigen en vrijwilligers. Daarnaast
                spelen er voor u als ouder vaak extra veel vragen en gevoelens
                mee, omdat het haast niet te bevatten is wat er met uw kind
                gebeurt.
              </p>
              <p>
                Het Kenniscentrum Kinderpalliatieve Zorg helpt u als ouder om
                helderheid te krijgen in de zorg die er is voor uw kind en
                uzelf. De organisatie KinderThuisZorg heeft een website gemaakt
                met herkenbare verhalen en tips voor ouders, kinderen en andere
                dierbaren: Hou Me Vast.
              </p>
              <Link indent href="https://www.kinderpalliatief.nl/kind-gezin">
                Ga naar kinderpalliatief.nl
              </Link>
              <Link
                indent
                href="http://www.houmevast.nl/pages.aspx?PageID=3&SubPageID=55&Levl=1"
              >
                Ga naar houmevast.nl
              </Link>
            </Section>
          </div>
        </div>

        <Separator />

        <div className="row" id="kosten-en-vragen">
          <div className="col-md-5">
            <Section className={styles.about} intro>
              <Heading rank={3}>
                Kosten en vergoeding van palliatieve zorg
              </Heading>
              <p>
                Palliatieve zorg krijgt u voor een groot deel vergoed door uw
                zorgverzekering. Soms loopt de vergoeding ook via de Wet
                langdurige zorg (de Wlz) en de Wet maatschappelijke
                ondersteuning (Wmo). Voor sommige soorten zorg geldt een eigen
                bijdrage.
              </p>
              <Button href="/over/kosten" color="green">
                Lees meer over de kosten van palliatieve zorg
              </Button>
            </Section>
          </div>

          <div className="col-md-6 offset-md-1">
            <Section
              color="pink"
              float="right"
              style={{ marginBottom: 0 }}
              intro
            >
              <Heading rank={3}>Waar kunt u terecht met vragen</Heading>
              <p>
                U krijgt in deze periode zorg van verschillende organisaties.
                Dat betekent dat het misschien niet altijd meteen duidelijk is
                bij wie u moet zijn als u hier vragen over heeft. We leggen in
                grote lijnen uit waar u terecht kunt.
              </p>
              <Button href="/over/vragen">
                Vragen over palliatieve zorg en hulp
              </Button>
            </Section>
          </div>
        </div>
      </div>
    </>
  );
};

export default home;
