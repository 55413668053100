import React from 'react';
import styles from './SideDrawer.module.scss';
import NavigationItems from '../NavigationItems/NavigationItems';
import { ReactComponent as CloseIcon } from './../../assets/icons/times.svg';
import Backdrop from './../Backdrop/Backdrop';

const sideDrawer = props => {
  let classes = [styles.wrapper, styles.closed];

  if (props.show) {
    classes = [styles.wrapper, styles.open];
  }

  return (
    <>
      <Backdrop show={props.show} clickHandler={props.closeHandler} />
      <div className={classes.join(' ')}>
        <button className={styles.closeButton} onClick={props.closeHandler}>
          <CloseIcon />
        </button>

        <div>
          <NavigationItems closeHandler={props.closeHandler} />
        </div>
      </div>
    </>
  );
};

export default sideDrawer;
