import React from 'react';
import styles from './Section.module.scss';

import Button from './../Button/Button';

const section = props => {
  const classes = [styles.wrapper];
  let button;

  if (props.color) {
    const color = props.color.charAt(0).toUpperCase() + props.color.slice(1);

    classes.push(styles[`color${color}`]);
  }

  if (props.float) {
    classes.push(props.float === 'right' ? styles.right : styles.left);
  }

  if (props.intro) {
    classes.push(styles.wrapperIntro);
  }

  if (props.button) {
    button = (
      <Button className={styles.button} color="quote" href={props.button.href}>
        {props.button.copy}
      </Button>
    );

    classes.push(styles.wrapperWithButton);
  }

  classes.push(props.className);

  return (
    <div className={classes.join(' ')} id={props.id} style={props.style}>
      {props.children}
      {button}
    </div>
  );
};

export default section;
