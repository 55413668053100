import React from 'react';
import styles from './screens.module.scss';
import data from './../data/stories.json';

import { Helmet } from 'react-helmet';
import Title from './../components/Title/Title';
import Separator from './../components/Separator/Separator';
import IntroCopy from './../components/IntroCopy/IntroCopy';
import Section from './../components/Section/Section';
import Heading from './../components/Heading/Heading';
import Quote from './../components/Quote/Quote';
import Hero from './../components/Hero/Hero';
import Button from './../components/Button/Button';

const home = ({ match, history }) => {
  const slug = match.params.slug;
  const item = data.find(item => item.slug === slug);

  // Redirect to stories overview when slug was not found
  if (!item) {
    history.push({
      pathname: '/ervaringsverhalen'
    });
  }

  const getOffset = function(blocks, block, index) {
    const previousIndex = index > 0 ? index - 1 : null;
    const previousBlock = previousIndex >= 0 ? blocks[previousIndex] : null;
    const odd = index === 0 || index % 2 === 0;

    if (
      odd ||
      block.type === 'quote' ||
      (previousBlock && previousBlock.type === 'quote' && block.type === 'text')
    ) {
      return 'offset-md-1';
    }

    return '';
  };

  const blocks = item.article.blocks.map((block, i) => {
    const offset = getOffset(item.article.blocks, block, i);

    switch (block.type) {
      case 'quote': {
        return (
          <div className={`col-md-4 ${offset}`} key={`block-${i}`}>
            <Section>
              <Quote
                icon
                quote={block.quote}
                cite={block.cite}
                age={block.age}
              />
            </Section>
          </div>
        );
      }
      default: {
        return (
          <div className={`col-md-5 ${offset}`} key={`block-${i}`}>
            <Section>
              <Heading rank={2} className="article">
                {block.title}
              </Heading>
              <p>{block.body}</p>
            </Section>
          </div>
        );
      }
    }
  });

  return (
    <>
      <Helmet>
        <title>{item.article.meta.title} | Palliatieve zorg</title>
        <meta name="description" content={item.article.meta.description} />
        <meta name="keywords" content={item.article.meta.keywords} />
      </Helmet>
      <div className="container-fluid">
        <Hero>
          <Title
            title={item.article.title}
            fixedBackgroundHeight
            rank={1}
            style={{ marginBottom: 0 }}
            image={{
              src: item.article.images.mobile,
              desktopSrc: item.article.images.desktop,
              position: 'top',
              alt: item.article.images.alt
            }}
          />
          <Heading
            rank={2}
            className="h4"
            color="red"
            style={{ marginBottom: 32 }}
          >
            {item.article.subtitle}
          </Heading>
          <IntroCopy>{item.article.intro}</IntroCopy>
        </Hero>

        <Separator />

        <div className="row">{blocks}</div>

        <Separator />

        <div className="row" id="kosten-en-vragen">
          <div className="col-md-5">
            <Section className={styles.about} intro>
              <Heading rank={3}>Ontdek de verhalen van anderen</Heading>
              <p>
                Hoe denken anderen over de tijd die ze nog hebben? En welke rol
                speelt palliatieve zorg daarin?
              </p>
              <Button href="/ervaringsverhalen" color="green">
                Naar alle verhalen
              </Button>
            </Section>
          </div>

          <div className="col-md-6 offset-md-1">
            <Section
              color="pink"
              float="right"
              style={{ marginBottom: 0 }}
              intro
            >
              <Heading rank={3}>Zorg en hulp voor u en uw dierbaren</Heading>
              <p>
                Wat heeft u nodig om u in deze periode zo goed mogelijk te
                voelen? Er staan professionals en vrijwilligers klaar om u te
                helpen.
              </p>

              <Button href="/zorg-en-hulp">Er is zorg en hulp voor u</Button>
            </Section>
          </div>
        </div>
      </div>
    </>
  );
};

export default home;
