import React from 'react';
import styles from './Title.module.scss';

import Heading from './../Heading/Heading';

const title = props => {
  const classes = [styles.wrapper];
  let headingRank = props.rank ? props.rank : 1;
  let visual;
  let visualMobile;
  let visualDesktop;

  if (props.fixedBackgroundHeight) {
    classes.push(styles.wrapperWithFixedBackgroundHeight);
  }

  if (props.image && props.image.src) {
    visualMobile = (
      <img className="d-md-none" src={props.image.src} alt={props.image.alt} />
    );
  }

  if (props.image && props.image.desktopSrc) {
    visualDesktop = (
      <img
        className="d-none d-md-block"
        src={props.image.desktopSrc}
        alt={props.image.alt}
      />
    );
  }

  if (props.image) {
    visual = (
      <>
        <div
          className={`${styles.visual} ${styles[props.image.position] ||
            styles.top} col-md-4`}
        >
          {visualMobile}
          {visualDesktop}
        </div>
      </>
    );
  }

  classes.push(props.className);

  return (
    <div className={classes.join(' ')} style={props.style}>
      {visual}
      <Heading rank={headingRank} className="h1" color="green">
        {props.title}
      </Heading>

      {props.children}
    </div>
  );
};
export default title;
