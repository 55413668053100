import React from 'react';
import styles from './Button.module.scss';
import { HashLink as Link } from 'react-router-hash-link';
import { scrollTo } from './../../utils/scroll';

const button = props => {
  const classes = [styles.button];

  if (props.className) {
    classes.push(props.className);
  }

  if (props.color) {
    const color = props.color.charAt(0).toUpperCase() + props.color.slice(1);

    classes.push(styles[`color${color}`]);
  }

  return (
    <Link
      to={props.href}
      className={classes.join(' ')}
      style={props.style}
      scroll={el => scrollTo(el, 0)}
    >
      {props.children}
    </Link>
  );
};

export default button;
