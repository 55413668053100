import React from 'react';
import styles from './TopicList.module.scss';

import Topic from './../Topic/Topic';

import IconBeslissen from './../../assets/icons/beslissen.svg';
import IconOpties from './../../assets/icons/opties.svg';
import IconCheck from './../../assets/icons/check.svg';

const topicList = props => {
  const content = [
    {
      title: 'Zo begint u het gesprek',
      icon: IconBeslissen,
      quote:
        '“Beslissingen over mijn zorg en afscheid nemen we samen. We bespreken de voor- en nadelen en proberen vervolgens tot een overeenstemming te komen.”',
      cite: 'Els',
      age: '63 jaar',
      copy:
        'Wat is voor u belangrijk in de laatste periode van uw leven? En hoe begint u hierover met uw dierbaren? Deze tips en ervaringen van anderen helpen u op weg.',
      button: {
        href: '/gesprek',
        copy: 'Begin met deze tips'
      }
    },
    {
      title: 'Er is zorg en hulp voor u',
      icon: IconOpties,
      quote:
        '“De vrijwilligers, coördinatrices, verpleegkundigen en huisarts doen er alles aan om het de gasten zo comfortabel mogelijk te maken. We volgen altijd hun wensen, ook wat betreft privacy. Sommige gasten willen veel aandacht, anderen willen meer op zichzelf gelaten worden.”',
      cite: 'Han Wassenberg (huisarts)',
      age: '69 jaar',
      copy:
        'U kunt van verschillende kanten palliatieve zorg en hulp krijgen. Van uw arts(en) en verpleegkundigen, en van uw gemeente. Er staan ook vrijwilligers voor u klaar. En als uw familie en vrienden voor u zorgen, dan kunnen ook zijzelf op zorg en hulp rekenen. ',
      button: {
        href: '/zorg-en-hulp',
        copy: 'Meer over zorg en hulp'
      }
    },
    {
      title: 'Belangrijke keuzes om te maken',
      icon: IconCheck,
      quote:
        '“Het geeft me een goed gevoel om zaken voor te bereiden, zodat ik daar niet meer over hoef na te denken. Zo krijg ik rust in mijn hoofd.”',
      cite: 'Monique',
      age: '66 jaar',
      copy:
        'Als het einde van uw leven dichterbij komt, dan krijgt u te maken met een aantal keuzes. Bijvoorbeeld of u een vertegenwoordiger wilt aanwijzen. Het is goed om hier op tijd over na te denken.',
      button: {
        href: '/keuzes',
        copy: 'Bekijk belangrijke keuzes'
      }
    }
  ];

  const items = content.map((item, index) => {
    return <Topic key={index} className={`col-md-4`} {...item} />;
  });

  return <div className={`row ${styles.wrapper}`}>{items}</div>;
};

export default topicList;
