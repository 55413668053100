import React, { useEffect } from 'react';
import styles from './screens.module.scss';

import Hero from './../components/Hero/Hero';
import Title from './../components/Title/Title';
// import titleStyles from './../components/Title/Title.module.scss';
import Separator from './../components/Separator/Separator';
import IntroCopy from './../components/IntroCopy/IntroCopy';
// import Quote from './../components/Quote/Quote';
import QuickNav from './../components/QuickNav/QuickNav';
import Section from './../components/Section/Section';
import mobileVisual from './../assets/images/vrouw-die-palliatieve-zorg-krijgt-schildert-in-haar-zolder-atelier.jpg';
import desktopVisual from './../assets/images/vrouw-die-palliatieve-zorg-krijgt-schildert-in-haar-zolder-atelier-large.jpg';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import Link from './../components/Link/Link';
import Heading from './../components/Heading/Heading';
import Questions from './../components/Questions/Questions';
import Question from './../components/Question/Question';
import Answer from './../components/Answer/Answer';
import Button from './../components/Button/Button';
import { Helmet } from 'react-helmet';
import * as Stickyfill from 'stickyfilljs';

const Conversation = props => {
  useEffect(() => {
    const elements = document.querySelectorAll('.sticky');
    Stickyfill.add(elements);
  }, []);

  return (
    <>
      <Helmet>
        <title>Zo begint u het gesprek | Palliatieve zorg</title>
        <meta
          name="description"
          content="Praten over de dood en palliatieve zorg: veel mensen vermijden het te lang. Deze tips helpen u om het gesprek te beginnen, met uw dierbaren en uw huisarts."
        />
        <meta
          name="keywords"
          content="praten over de dood, praten over levensvragen"
        />
      </Helmet>
      <div className="container-fluid">
        <Hero>
          <Title
            title="Zo begint u het gesprek"
            fixedBackgroundHeight
            image={{
              src: mobileVisual,
              desktopSrc: desktopVisual,
              position: 'top',
              alt:
                'Man die palliatieve zorg krijgt, zit thuis achter zijn computer'
            }}
          />
          <IntroCopy>
            Het geeft rust als u uw omgeving heeft verteld wat u wilt in deze
            laatste fase van uw leven. Welke zorg en hulp u nodig heeft,
            bijvoorbeeld. Waar u wilt zijn in uw laatste levensdagen, en met
            wie. Soms is het niet makkelijk om hierover te beginnen. De tips op
            deze pagina helpen u hopelijk op weg.
          </IntroCopy>

          <QuickNav />
        </Hero>

        <Separator />

        <Section className="row" id="praten-met-uw-naasten">
          <div className={`col-md-3 offset-md-1 sticky ${styles.sticky}`}>
            <Heading rank={2}>Praten met uw naasten</Heading>
            <p>
              Een gesprek aangaan met uw dierbaren over het einde van uw leven:
              dat kan nog best moeilijk zijn. Toch is het aan te raden om in elk
              geval met een paar mensen te delen wat er in u omgaat, en wat u
              nog wilt doen.
            </p>

            <div className="d-none d-md-block" style={{ marginTop: 48 }}>
              <Heading rank={3} className="h4" color="green">
                Moeite om met het gesprek aan te gaan?
              </Heading>
              <p>
                Als u iemand bent die zich rustig wil oriënteren, dan kunt u
                beginnen met een gesprek met de Luisterlijn. Of met het lezen
                van een e-book.
              </p>
              <Link href="https://www.deluisterlijn.nl/" indent>
                Praten met de Luisterlijn
              </Link>
              <Link href="https://www.ikwilmetjepraten.nu/e-books/" indent>
                E-books op ikwilmetjepraten.nu
              </Link>
              <Link
                href="https://www.netwerkpalliatievezorg.nl/Portals/139/Wensenboekje.pdf"
                indent
              >
                Wensen voor mijn leven in de laatste levensfase
              </Link>
            </div>
          </div>

          <div className="col-md-6 offset-md-1">
            <Accordion
              allowMultipleExpanded={true}
              allowZeroExpanded={true}
              preExpanded={[0]}
            >
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span className="suptitle">Tip 1</span>
                    <span className="title">
                      Deel grote stappen op in kleinere
                    </span>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Begin klein. Kies bijvoorbeeld één persoon die u goed
                    vertrouwt. Nodig hem of haar uit en vertel dat het gaat om
                    iets dat voor u heel belangrijk is. Zo heeft u alvast een
                    begin gemaakt.
                  </p>
                  <Link href="https://www.ikwilmetjepraten.nu/tips-om-een-gesprek-te-starten/">
                    Tips om een gesprek te starten
                  </Link>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span className="suptitle">Tip 2</span>
                    <span className="title">Zorg voor rust</span>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Kies een rustige plek uit en zorg ervoor dat u niet gestoord
                    kunt worden door telefoon of bezoek.
                  </p>

                  <Link href="https://www.ikwilmetjepraten.nu/praten-met-je-naasten/">
                    Bekijk meer tips, video's en e-books
                  </Link>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span className="suptitle">Tip 3</span>
                    <span className="title">
                      U hoeft niet meteen alles te zeggen
                    </span>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Een goede manier om die eerste drempel te overwinnen, is
                    door u eerst te richten op een kort gesprek. Begin met een
                    gesprek van een kwartier of een half uur. In een
                    vervolggesprek kunt u er dieper op ingaan.
                  </p>
                  <Link href="https://www.ikwilmetjepraten.nu/gespreksonderwerpen/">
                    Bekijk tips voor gespreksonderwerpen
                  </Link>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>

          <div className={`order-last col-md-3 offset-md-1 d-md-none`}>
            <Heading rank={3} className="h4" color="green">
              Moeite om met het gesprek aan te gaan?
            </Heading>
            <p>
              Als u iemand bent die zich rustig wil oriënteren, dan kunt u
              beginnen met een gesprek met de Luisterlijn. Of met het lezen van
              een e-book.
            </p>
            <Link href="https://www.deluisterlijn.nl/" indent>
              Praten met de Luisterlijn
            </Link>
            <Link href="https://www.ikwilmetjepraten.nu/e-books/" indent>
              E-books op ikwilmetjepraten.nu
            </Link>
            <Link
              href="https://www.netwerkpalliatievezorg.nl/Portals/139/Wensenboekje.pdf"
              indent
            >
              Wensen voor mijn leven in de laatste levensfase
            </Link>
          </div>
        </Section>

        <Separator />

        <Section className="row">
          <div className="col-md-5">
            <Accordion
              allowMultipleExpanded={true}
              allowZeroExpanded={true}
              preExpanded={[0]}
            >
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span className="suptitle">Tip 1</span>
                    <span className="title">
                      Tip van een lotgenote: neem iemand mee
                    </span>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Judith kreeg te maken met uitgezaaide borstkanker. Ze heeft
                    verschillende blogartikelen geschreven over haar ervaringen.
                    In dit artikel geeft ze 9 tips voor afspraken bij de dokter.
                    Zoals: 'neem altijd iemand mee'.
                  </p>
                  <Link href="https://palvooru.nl/goed-gesprek-met-de-arts-9-stappen/">
                    Meer tips van Judith op palvooru.nl
                  </Link>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span className="suptitle">Tip 2</span>
                    <span className="title">Gebruik de 3 goede vragen</span>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Het helpt heel goed om voor het gesprek met de huisarts
                    alvast een paar vragen op te schrijven. Begin bijvoorbeeld
                    met deze 3 vragen:
                  </p>
                  <ol>
                    <li>Wat zijn mijn mogelijkheden?</li>
                    <li>Wat zijn daar de voor- en nadelen van?</li>
                    <li>Wat betekent dat voor mij?</li>
                  </ol>

                  <Link href="https://3goedevragen.nl/">
                    Meer uitleg op 3goedevragen.nl
                  </Link>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span className="suptitle">Tip 3</span>
                    <span className="title">
                      Tip van een lotgenote: gesprek opnemen
                    </span>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Judith schreef op palvooru.nl over haar gesprekken met
                    oncologen over haar uitgezaaide borstkanker. Zij raadt aan
                    om uw mobiele telefoon te gebruiken om de gesprekken op te
                    nemen. Vraag wel altijd aan het begin van het gesprek of de
                    arts het goed vindt dat u het gesprek opneemt.
                  </p>

                  <Link href="https://palvooru.nl/goed-gesprek-met-de-arts-9-stappen/">
                    Meer tips van Judith op Palvooru.nl
                  </Link>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>

          <div
            className={`order-first col-md-4 offset-md-1 order-md-1 sticky ${
              styles.sticky
            }`}
            id="praten-met-uw-huisarts"
          >
            <Section>
              <Heading rank={2}>Praten met uw huisarts</Heading>
              <p>
                Het is zo belangrijk dat uw artsen weten wat u wilt, en wat
                juist niet. Uw huisarts kan u bovendien helpen met het voorkomen
                of verlichten van klachten, zoals pijn of benauwdheid. Ook kan
                de arts helpen bij het omgaan met gevoelens, zoals angst of
                verdriet.
              </p>
              <Link
                href="https://www.thuisarts.nl/bezoek-aan-huisarts/ik-wil-samen-beslissen-met-mijn-huisarts"
                indent
              >
                Gesprek met de huisarts voorbereiden
              </Link>
              <Link
                href="https://www.thuisarts.nl/levenseinde/ik-wil-nadenken-en-praten-over-mijn-levenseinde"
                indent
              >
                Waar kan de huisarts u mee helpen
              </Link>
            </Section>
          </div>
        </Section>

        <Separator />

        <Section intro>
          <div className="row" id="praten-over-levensvragen">
            <div className="col offset-md-1">
              <Heading rank={2}>Praten over levensvragen</Heading>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 offset-md-1">
              <Section>
                <p>
                  U heeft waarschijnlijk veel vragen om te overdenken. Van
                  praktische vragen tot hele diepgaande, zoals 'Waarom overkomt
                  mij dit?' 'Heeft mijn leven zin gehad?' en ‘Kan ik de dood
                  accepteren?’
                </p>
                <p>
                  Praten over deze levensvragen heeft bij veel mensen een
                  positief effect op de manier waarop zij de laatste fase van
                  hun leven ervaren.
                </p>
                <Link href="https://geestelijkeverzorging.nl">
                  Meer over levensvragen: geestelijkeverzorging.nl
                </Link>
              </Section>
            </div>
          </div>
        </Section>
      </div>

      <Questions className="row">
        <div className="col">
          <div className="container-fluid">
            <div className="row">
              <Question title="Vraag 1" className="col-9 col-md-5 offset-md-1">
                Wat houdt u gaande?
              </Question>
              <Answer
                quotes
                title="Monique"
                className="col-9 offset-3 col-md-6 offset-md-5"
              >
                Elke ochtend sta ik op en probeer ik wat van mijn dag te maken
                zodat ik ‘s avonds met een voldaan gevoel naar bed kan gaan.
              </Answer>

              <Question title="Vraag 2" className="col-9 col-md-5 offset-md-1">
                Waar leeft u voor?
              </Question>
              <Answer
                quotes
                title="Monique"
                className="col-9 offset-3 col-md-6 offset-md-5"
              >
                Eigenlijk zorg ik altijd voor anderen. Mijn ziekte komt daarom
                helemaal niet goed uit; ik kan helemaal niet dood. Mijn man is
                ernstig ziek en heeft veel hulp nodig. Hij kan slecht tegen
                stress en wordt daar benauwd van. Daarnaast leunt mijn zoon heel
                erg op mij. Ik probeer alles daarom zo goed mogelijk te regelen.
              </Answer>

              <Question title="Vraag 3" className="col-9 col-md-5 offset-md-1">
                Waar is uw thuis?
              </Question>
              <Answer
                quotes
                title="Wiljohn"
                className="col-9 offset-3 col-md-6 offset-md-5"
              >
                Ik word intensief ondersteund door drie verpleegkundigen. Zonder
                hen had ik niet thuis kunnen blijven. Door hun hulp kunnen de
                kinderen in hun eigen bed slapen en woon ik op loopafstand van
                vrienden en familie. Mijn situatie verandert, maar op deze
                manier blijft alles wel dichtbij en vertrouwd.
              </Answer>

              <Question title="Vraag 4" className="col-9 col-md-5 offset-md-1">
                Wie ziet u staan?
              </Question>
              <Answer
                quotes
                title="Wiljohn"
                className="col-9 offset-3 col-md-6 offset-md-5"
              >
                Inmiddels kan ik niet alleen met een goede vriendin, maar ook
                met andere lieve vrienden en familieleden goed praten. Voor hen
                is het ook fijn; door onze gesprekken ben ik als mens heel
                toegankelijk gebleven. Ze komen graag bij me langs.
              </Answer>
            </div>
          </div>
        </div>
      </Questions>

      <div className="container-fluid">
        <div className="row">
          <Separator />
        </div>
      </div>

      <div className="row bg-right bg-pink">
        <div className="col">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-7 offset-md-5">
                <Section color="pink" style={{ marginBottom: 0 }} float="right">
                  <Heading rank={3}>Naar de andere onderwerpen</Heading>
                  <p>
                    U heeft nu gezien waar u kunt beginnen met het praten over
                    uw situatie. We laten u nog graag zien wat voor zorg en hulp
                    er voor u is. En we geven u een overzicht van belangrijke
                    keuzes waar u mee te maken kunt krijgen.
                  </p>

                  <Button href="/zorg-en-hulp">
                    Er is zorg en hulp voor u
                  </Button>
                  <Button href="/keuzes">Keuzes om te maken</Button>
                </Section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Conversation;
