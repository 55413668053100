import React from 'react';
import styles from './Quote.module.scss';

const cite = props => {
  return (
    <cite className={styles.cite}>
      <strong>{props.cite}</strong>
      {props.age}
    </cite>
  );
};

export default cite;
