export const getSegments = url => {
  const segments = url.split('/');
  const splitted = segments.filter(item => {
    return item !== '';
  });

  return splitted;
};

export const isSubRoute = segments => {
  if (segments.length < 2) {
    return false;
  }

  return true;
};

export default { getSegments, isSubRoute };
