import React from 'react';
import styles from './screens.module.scss';

import Title from './../components/Title/Title';
import IntroCopy from './../components/IntroCopy/IntroCopy';
import Section from './../components/Section/Section';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';

const home = () => {
  return (
    <>
      <Helmet>
        <title>Pagina niet gevonden | Palliatieve zorg</title>
      </Helmet>
      <div className="container-fluid">
        <Section>
          <div className={`row ${styles.background}`}>
            <div className="col-md-8">
              <Title title="Oeps, deze pagina bestaat niet" />
              <IntroCopy>
                Controleer of de link correct is of kies uit de onderstaande
                lijst:
              </IntroCopy>
            </div>
          </div>
        </Section>

        <div className="row">
          <div className="col-md-7 offset-md-2">
            <Section>
              <ul className={styles.noBullets}>
                <li>
                  <Link to="/home">Home</Link>
                </li>
                <li>
                  <Link to="/gesprek">Gesprek</Link>
                </li>
                <li>
                  <Link to="/zorg-en-hulp">Zorg en hulp</Link>
                </li>
                <li>
                  <Link to="/keuzes">Keuzes</Link>
                </li>
                <li>
                  <Link to="/over">Over palliatieve zorg</Link>
                  <ul>
                    <li>
                      <Link to="/over/vragen">Vragen</Link>
                    </li>
                    <li>
                      <Link to="/over/kosten">Kosten</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </Section>
          </div>
        </div>
      </div>
    </>
  );
};

export default home;
