export const getOffsetScrollPosition = (el, offset) => {
  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = el.getBoundingClientRect().top;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - offset;

  return offsetPosition;
};

export const scrollTo = (el, offset) => {
  window.scrollTo({
    top: getOffsetScrollPosition(el, offset),
    left: 0,
    behavior: 'smooth'
  });
};

export function scrolledPastEl(id, y) {
  const el = document.getElementById(id);

  if (!el) {
    return false;
  }

  const { top, height } = el.getBoundingClientRect();

  if (top + height > 0) {
    return false;
  }

  return true;
}

export default {
  getOffsetScrollPosition,
  scrollTo,
  scrolledPastEl
};
