import React from 'react';
import styles from './Logo.module.scss';
import { Link } from 'react-router-dom';

const logo = () => {
  return (
    <Link title="Ga naar home" to="/" className={styles.logo}>
      Palliatieve Zorg
    </Link>
  );
};

export default logo;
