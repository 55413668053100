import React from 'react';
import styles from './screens.module.scss';

import Title from './../components/Title/Title';
import IntroCopy from './../components/IntroCopy/IntroCopy';
import Section from './../components/Section/Section';
import Link from './../components/Link/Link';

const home = () => {
  return (
    <>
      <div className="container-fluid">
        <Section>
          <div className={`row ${styles.background}`}>
            <div className="col-md-8">
              <Title title="Contact" />
              <IntroCopy>
                U kunt contact opnemen met coöperatie Palliatieve Zorg Nederland
                via{' '}
                <Link
                  style={{ display: 'inline-block' }}
                  href="mailto:info@pznl.nl"
                >
                  info@pznl.nl
                </Link>
              </IntroCopy>
            </div>
          </div>
        </Section>
      </div>
    </>
  );
};

export default home;
