import React from 'react';
import styles from './Footer.module.scss';

import { Link } from 'react-router-dom';
import Heading from './../Heading/Heading';

const footer = props => {
  return (
    <section className={`container ${styles.wrapper}`}>
      <div className={`row`}>
        <div className={`col-sm-5 col-lg-2 offset-lg-6 ${styles.list}`}>
          <Heading rank={3} className="h5" color="pink">
            Service
          </Heading>
          <ul>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <Link to="/sitemap">Sitemap</Link>
            </li>
          </ul>
        </div>

        <div
          className={`col-sm-5 offset-sm-1 col-lg-2 offset-lg-0 ${styles.list}`}
        >
          <Heading rank={3} className="h5" color="pink">
            Over deze site
          </Heading>
          <ul>
            <li>
              <Link to="/privacy">Privacy</Link>
            </li>
            <li>
              <Link to="/cookies">Cookies</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className={`row`}>
        <div className="offset-lg-6 col-lg-6">
          <p className={styles.about}>
            Deze website is tot stand gekomen in samenwerking van Ministerie van
            Volksgezondheid, Welzijn en Sport en coöperatie Palliatieve Zorg
            Nederland.
          </p>
        </div>
      </div>
    </section>
  );
};

export default footer;
