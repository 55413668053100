import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Layout from './components/Layout/Layout';
import Home from './screens/Home';
import Conversation from './screens/Conversation';
import Help from './screens/Help';
import Choices from './screens/Choices';
import About from './screens/About';
import Cookies from './screens/Cookies';
import Privacy from './screens/Privacy';
import Questions from './screens/Questions';
import Costs from './screens/Costs';
import NotFound from './screens/NotFound';
import Sitemap from './screens/Sitemap';
import Contact from './screens/Contact';
import Stories from './screens/Stories';
import Story from './screens/Story';

function App() {
  return (
    <div>
      <Layout>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/gesprek" component={Conversation} />
          <Route path="/zorg-en-hulp" component={Help} />
          <Route path="/keuzes" component={Choices} />
          <Route path="/over/vragen" component={Questions} />
          <Route path="/over/kosten" component={Costs} />
          <Route path="/over" component={About} />
          <Route path="/cookies" component={Cookies} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/sitemap" component={Sitemap} />
          <Route path="/contact" component={Contact} />
          <Route path="/ervaringsverhalen/:slug" component={Story} />
          <Route path="/ervaringsverhalen" component={Stories} />
          <Route path="/404" component={NotFound} />
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </div>
  );
}

export default App;
