import React from 'react';
import styles from './Hero.module.scss';

const hero = props => {
  return (
    <div className={`row ${styles.wrapper}`}>
      <div className={`col-md-6 ${styles.content}`}>{props.children}</div>
    </div>
  );
};

export default hero;
