import React from 'react';
import styles from './Topic.module.scss';

import Cite from './../Quote/Cite';
import Button from './../Button/Button';
import Heading from './../Heading/Heading';

const topic = props => {
  return (
    <>
      <div className={`${props.className}`}>
        <div className={styles.quote}>
          <Heading rank={3} className="h4">
            <img src={props.icon} alt="Icoon" className={styles.icon} />
            {props.title}
          </Heading>
          <blockquote>
            <p>{props.quote}</p>
          </blockquote>
          <Cite {...props} />
        </div>
      </div>

      <div className={props.className}>
        <div className={styles.copyWrapper}>
          <p className={styles.copy}>{props.copy}</p>
          <Button href={props.button.href} style={{ marginTop: 'auto' }}>
            {props.button.copy}
          </Button>
        </div>
      </div>
    </>
  );
};

export default topic;
