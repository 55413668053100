import React from 'react';
import styles from './screens.module.scss';

import Title from './../components/Title/Title';
import Separator from './../components/Separator/Separator';
import IntroCopy from './../components/IntroCopy/IntroCopy';
import Section from './../components/Section/Section';
import Heading from './../components/Heading/Heading';
import Link from './../components/Link/Link';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';

const home = () => {
  return (
    <>
      <Helmet>
        <title>Kosten en vergoeding | Palliatieve zorg</title>
        <meta
          name="description"
          content="De kosten van palliatieve zorg vergoedt uw zorgverzekeraar meestal. Als u al onder de Wet Langdurige Zorg (Wlz) valt, dan blijft dit voor u hetzelfde."
        />
        <meta
          name="keywords"
          content="kosten palliatieve zorg, vergoeding palliatieve zorg"
        />
      </Helmet>
      <div className="container-fluid">
        <Section>
          <div className={`row ${styles.background}`}>
            <div className="col-md-8">
              <Title title="Kosten van palliatieve zorg" />
              <IntroCopy>
                U krijgt palliatieve zorg meestal vergoed via uw
                zorgverzekering. Krijgt u al zorg via de Wet Langdurige Zorg?
                Dan krijgt u ook de palliatieve zorg via deze wet vergoed.
                Verder vragen veel hospices een vergoeding per dag. Op deze
                pagina leggen we uit wat u kunt verwachten.
              </IntroCopy>
            </div>
          </div>
        </Section>

        <Separator />

        <div className="row">
          <div className="col-md-7 offset-md-2">
            <Section>
              <Heading rank={2}>Meestal betaalt de zorgverzekering</Heading>
              <p>
                Palliatieve zorg wordt meestal vergoed via uw zorgverzekering.
                Voor zorg die uw zorgverzekeraar vergoedt, betaalt u wel
                (meestal) een eigen risico. U hoeft geen eigen risico te betalen
                voor verpleging en verzorging thuis en voor zorg door uw
                huisarts.
              </p>
            </Section>
            <Section>
              <Heading rank={2}>
                Tenzij u al langdurige zorg krijgt vanuit de Wet Langdurige Zorg
                (de Wlz)
              </Heading>
              <p>
                Het kan zijn dat u al zorg thuis of in het verpleeghuis krijgt
                vanuit de Wet Langdurige Zorg (de Wlz). U heeft hiervoor dan een
                indicatie (toestemming) gekregen voor de Wet Langdurige Zorg.
                Het CIZ is de organisatie die deze indicatie afgeeft.
              </p>
              <p>
                Valt de zorg die u krijgt al onder de Wet Langdurige Zorg? Dan
                geldt dit ook voor uw palliatieve zorg. Alleen als u in het
                ziekenhuis ligt, wordt uw zorg daar wel door de zorgverzekeraar
                vergoed.
              </p>
              <p>
                Als u vragen heeft over langdurige zorg, dan kunt u contact
                opnemen met uw vaste zorgverlener, of als u die (nog) niet
                heeft, met uw zorgkantoor.
              </p>
              <Link indent href="https://www.zn.nl/350584833/Zorgkantoren">
                Bekijk zorgkantoren
              </Link>
            </Section>
            <Section>
              <Heading rank={2}>Vergoeding voor verblijf in hospice</Heading>
              <p>
                De meeste hospices vragen een vergoeding per dag voor de kosten
                die zij maken. Meer informatie hierover kunt u opvragen bij het
                hospice waar u naartoe wilt.
              </p>
            </Section>
            <Section>
              <Heading rank={2}>Eigen bijdrage</Heading>
              <p>
                Bent u op dit moment al in een verpleeghuis, en krijgt u dit
                vergoed via de Wet Langdurige Zorg (Wlz)? Dan betaalt u hiervoor
                al een eigen bijdrage. Die bijdrage voor de Wlz blijft u ook in
                het hospice betalen, maar dan geldt meestal wel een lager
                tarief. De eigen bijdrage voor de Wlz staat los van de
                vergoeding die het hospice vraagt voor de kosten van verblijf.
              </p>
            </Section>
            <Section>
              <Heading rank={2}>Gemeente betaalt praktische hulp thuis</Heading>
              <p>
                De gemeente vergoedt vaak praktische hulp als u thuis blijft,
                dan gaat het bijvoorbeeld om extra hulp in huis of hulp voor uw
                mantelzorgers. Hiervoor geldt de Wet Maatschappelijke
                Ondersteuning: de WMO. Voor deze hulp betaalt u een eigen
                bijdrage van maximaal € 17,50 per 4 weken.
              </p>
            </Section>
            <Section>
              <Heading rank={3}>Wilt u meer informatie?</Heading>
              <HashLink to="/over/vragen" className={styles.link}>
                Kijk bij Vragen over palliatieve zorg
              </HashLink>
              <Link indent href="http://juisteloket.nl">
                Ga naar juisteloket.nl
              </Link>
              <Link
                indent
                href="https://www.informatielangdurigezorg.nl/volwassenen/ptz"
              >
                Ga naar informatielangdurigezorg.nl
              </Link>
              <Link
                indent
                href="https://iknl.nl/docs/default-source/PDF_Docs/handreiking-financiering-palliatieve-zorg-2019---versie-2.pdf"
              >
                Download de handreiking van het IKNL
              </Link>
            </Section>
          </div>
        </div>
      </div>
    </>
  );
};

export default home;
