import React from 'react';
import styles from './screens.module.scss';

import Title from './../components/Title/Title';
import Separator from './../components/Separator/Separator';
import IntroCopy from './../components/IntroCopy/IntroCopy';
import Section from './../components/Section/Section';
import Heading from './../components/Heading/Heading';
import Link from './../components/Link/Link';
import { Helmet } from 'react-helmet';

const home = () => {
  return (
    <>
      <Helmet>
        <title>Waar kunt u terecht met vragen | Palliatieve zorg</title>
        <meta
          name="description"
          content="U krijgt palliatieve zorg van verschillende organisaties. We leggen uit waar u terechtkunt als u hier vragen over heeft."
        />
        <meta name="keywords" content="palliatieve zorg, vragen" />
      </Helmet>
      <div className="container-fluid">
        <Section>
          <div className={`row ${styles.background}`}>
            <div className="col-md-8">
              <Title title="Waar kunt u terecht met vragen over palliatieve zorg" />
              <IntroCopy>
                U kunt in de laatste fase van uw leven zorg en hulp krijgen van
                verschillende organisaties. Op deze pagina leggen we in grote
                lijnen uit waar u terecht kunt voor welke vragen.
              </IntroCopy>
            </div>
          </div>
        </Section>

        <Separator />

        <div className="row">
          <div className="col-md-7 offset-md-2">
            <Section>
              <Heading rank={2}>
                Overleg met uw arts, ook als u nog twijfelt
              </Heading>
              <p>
                Een van de doelen van palliatieve zorg is dat u kunt overlijden
                op de plek waar u zich het prettigst voelt. Dat kan thuis zijn,
                of bijvoorbeeld in een hospice.
              </p>
              <p>
                Het kan best zijn dat u op een gegeven moment ergens anders
                naartoe wilt. Van thuis naar een hospice bijvoorbeeld. Het doel
                is dat u en uw dierbaren zo weinig mogelijk last hebben van het
                regelen van deze overstap, ook niet als het snel moet gebeuren.
              </p>
              <p>
                Uw zorgverleners (zoals artsen en verpleegkundigen) moeten
                hiervoor samenwerken met elkaar en met uw zorgverzekeraar of uw
                zorgkantoor. Dit lukt het beste als ze op tijd weten wat u wilt,
                of welke keuzes u overweegt.
              </p>
            </Section>
            <Section>
              <Heading rank={2}>Begin bij uw behandelend arts</Heading>
              <p>
                Voor vragen, wensen en verwachtingen over de zorg en
                ondersteuning in de laatste fase van uw leven kunt u het beste
                op tijd contact zoeken met uw behandelend arts.
              </p>
              <Heading rank={3} className="h4" color="green">
                Zo weet u wie uw behandelend arts is
              </Heading>
              <p>Wie uw behandelend arts is, hangt af van waar u bent: </p>
              <ul>
                <li>Als u thuis bent: uw huisarts.</li>
                <li>Als u in het ziekenhuis bent: de medisch specialist.</li>
                <li>
                  Als u in een verpleeghuis bent: de specialist
                  ouderengeneeskunde (of soms nog uw eigen huisarts).
                </li>
                <li>
                  Als u in een hospice bent: uw huisarts of een vervangende
                  huisarts in de buurt van het hospice.
                </li>
              </ul>
            </Section>
            <Section>
              <Heading rank={2}>Vragen over praktische hulp</Heading>
              <p>
                U kunt bij uw gemeente vaak advies en hulp krijgen om zo lang
                mogelijk zelfstandig thuis te kunnen blijven wonen, ook aan het
                einde van uw leven. Denk hierbij bijvoorbeeld aan (extra) hulp
                bij het huishouden, hulp bij vervoer of begeleiding. Houd er wel
                rekening mee dat het aanvragen hiervan tijd kost.
              </p>
            </Section>
            <Section>
              <Heading rank={2}>Vragen over verpleegkundige zorg thuis</Heading>
              <p>
                Het is mogelijk om thuis verpleegkundige zorg te krijgen in uw
                laatste levensfase. De zorgverzekeraar vergoedt deze
                wijkverpleging. Als u vragen heeft over de vergoeding of het
                regelen van verpleegkundige zorg, dan kunt u het beste contact
                opnemen met uw zorgverzekeraar.
              </p>
            </Section>
            <Section>
              <Heading rank={2}>Vragen over langdurige zorg</Heading>
              <Heading rank={3} className="h4" color="green">
                Als u al zorg krijgt vanuit de Wet Langdurige Zorg (Wlz)
              </Heading>
              <p>
                Het kan zijn dat u al zorg thuis of in het verpleeghuis krijgt
                vanuit de Wet Langdurige Zorg (de Wlz). U heeft dan hiervoor een
                indicatie (toestemming) gekregen voor de Wet Langdurige Zorg.
                Het CIZ is de organisatie die deze indicatie afgeeft.
              </p>
              <Heading rank={3} className="h4" color="green">
                Vergoeding palliatieve zorg op dezelfde manier
              </Heading>
              <p>
                Als dit voor u geldt, dan valt ook uw palliatieve zorg onder de
                Wet Langdurige Zorg. U kunt hierover contact opnemen met uw
                vaste zorgverlener, of als u die (nog) niet heeft, met uw
                zorgkantoor.
              </p>
              <Link indent href="https://www.zn.nl/350584833/Zorgkantoren">
                Bekijk zorgkantoren
              </Link>
            </Section>
            <Section>
              <Heading rank={2}>Vragen over hospices</Heading>
              <p>
                Het hospice waar u opgenomen wilt worden kan u vertellen wat u
                moet regelen voordat u er naartoe kunt. Ook kan het hospice
                vertellen hoe hoog de eigen bijdrage is die u moet betalen.
              </p>
            </Section>
            <Section>
              <Heading rank={2}>Vragen over uw eigen regio?</Heading>
              <p>
                Wilt u meer weten over de palliatieve zorg en hulp bij u in de
                buurt, dan kunt u ook contact opnemen met de netwerkcoördinator
                palliatieve zorg in uw regio. Deze coördinator kan u meer
                vertellen over wat er bij u in de omgeving mogelijk is.
              </p>
              <Link
                indent
                href="https://www.netwerkpalliatievezorg.nl/zorg-in-uw-regio"
              >
                Naar netwerkpalliatievezorg.nl
              </Link>
            </Section>
            <Section>
              <Heading rank={2}>
                Klachten over een arts of zorginstelling
              </Heading>
              <p>
                Heeft u klachten over de zorg die u krijgt van een arts of
                zorginstelling? Gebruik dan het stappenplan van de
                Rijksoverheid.
              </p>
              <Link
                indent
                href="https://www.rijksoverheid.nl/onderwerpen/kwaliteit-van-de-zorg/vraag-en-antwoord/waar-kan-ik-terecht-met-een-klacht-over-een-arts-of-zorginstelling"
              >
                Stappenplan op rijksoverheid.nl
              </Link>
            </Section>
            <Section>
              <Heading rank={2}>
                Vragen of klachten over het regelen van zorg
              </Heading>
              <p>
                Heeft u vragen of klachten over het regelen van palliatieve zorg
                of de vergoeding ervan? Dan kunt u terecht bij het Juiste Loket.
                Dit loket helpt als er problemen zijn bij het regelen van
                langdurige zorg. U krijgt meestal direct antwoord. Als uw vraag
                ingewikkelder is, dan werkt het loket samen met een praktijkteam
                voor de palliatieve zorg.
              </p>
              <Link indent href="mailto:meldpunt@juisteloket.nl">
                meldpunt@juisteloket.nl
              </Link>
              <Link indent href="tel:0307897878">
                030-7897878
              </Link>
            </Section>
            <Section>
              <Heading rank={2}>Praktijkteam palliatieve zorg</Heading>
              <p>
                Als er ingewikkelde problemen zijn bij het regelen van goede
                zorg, dan kan het praktijkteam van de palliatieve zorg helpen.
                Dit is een team van experts. Zij werken bij het Ministerie van
                Volksgezondheid, Welzijn en Sport (VWS) en bij zorgverzekeraars,
                netwerken palliatieve zorg en de Coöperatie palliatieve zorg
                Nederland. Ook enkele palliatieve-zorgverleners doen mee.
              </p>
              <p>Het praktijkteam is te bereiken via het Juiste Loket:</p>
              <Link indent href="mailto:meldpunt@juisteloket.nl">
                meldpunt@juisteloket.nl
              </Link>
              <Link indent href="tel:0307897878">
                030-7897878
              </Link>
            </Section>
          </div>
        </div>
      </div>
    </>
  );
};

export default home;
