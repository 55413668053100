import React from 'react';
import styles from './SubNav.module.scss';

import { withRouter } from 'react-router-dom';
import { NavHashLink as Link } from 'react-router-hash-link';
import { scrollTo } from './../../utils/scroll';
import Scrollspy from 'react-scrollspy';

const SubNav = props => {
  const slugs = [];
  const classes = ['row', styles.wrapper];

  if (!props.show) {
    classes.push(styles.hidden);
  }

  const items = props.items.map((item, index) => {
    slugs.push(item.slug);

    return (
      <li key={index} className={styles.item}>
        <Link
          scroll={el => scrollTo(el, 100)}
          to={`${props.location.pathname}#${item.slug}`}
          location={{
            pathname: document.location.pathname + document.location.hash
          }}
        >
          {item.menu}
        </Link>
      </li>
    );
  });

  return (
    <div className={classes.join(' ')}>
      <div className="col align-self-center">
        <div className="container-fluid">
          <div className="row">
            <div className="offset-md-2 col-md-8">
              <Scrollspy
                className={styles.list}
                items={slugs}
                currentClassName={styles.current}
              >
                {items}
              </Scrollspy>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SubNav);
