import React from 'react';
import styles from './Cta.module.scss';

import { Link } from 'react-router-dom';

const cta = props => {
  return (
    <Link to={props.href} className={styles.cta}>
      <img src={props.icon} alt="Icoon" className={styles.icon} />
      {props.children}
    </Link>
  );
};

export default cta;
