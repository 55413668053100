import React from 'react';
import styles from './Heading.module.scss';

const heading = props => {
  const classes = [];
  const Tag = props.rank > 6 ? 'h6' : `h${props.rank}`;

  if (props.color) {
    const color = props.color.charAt(0).toUpperCase() + props.color.slice(1);

    classes.push(styles[`color${color}`]);
  }

  if (props.className) {
    classes.push(styles[props.className]);
  } else {
    classes.push(styles[`h${props.rank}`]);
  }

  return (
    <Tag className={classes.join(' ')} style={props.style}>
      {props.children}
    </Tag>
  );
};

export default heading;
