import React from 'react';
import styles from './Header.module.scss';
import Logo from '../Logo/Logo';
import { ReactComponent as MenuIcon } from './../../assets/icons/bars.svg';
import NavigationItems from '../NavigationItems/NavigationItems';

const header = props => {
  const classes = [styles.header];

  return (
    <header className={classes.join(' ')}>
      <div className="container-fluid">
        <div className={`row ${styles.row}`}>
          <div className="col-1 d-md-none">
            <button onClick={props.drawerToggleHandler}>
              <MenuIcon />
            </button>
          </div>
          <div className="col-11 col-md-4 col-lg-6">
            <Logo />
          </div>
          <div className="d-none d-md-block col-md-8 col-lg-6">
            <NavigationItems className={styles.list} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default header;
